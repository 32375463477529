<template>
  <div class="query_wrap">
    <h1 class="h_none">商标查询</h1>
    <h2 class="h_none">蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!-- <headers></headers> -->
    <div @click.stop="clossOpen" class="query040" :style="{background: !queryModes ? '#FFFFFF' : ''}" :class="{'have-result':queryModes}">
      <!--<h3 class="tiaoshi3">{{queryMode}}*{{firstChoosed}}</h3>-->
      <!-- <pubHeader001></pubHeader001> -->
      <div class="status_wrap" v-show="!queryModes">
        <div class="status-box">
        </div>
      </div>
      <div class="input_list_wrap">

        <section class="query-box"  :class="{'vertical-horizon-center':!queryModes}">
          <el-radio-group :class="{radio: queryModes}"  v-model="value" :style="{display:'block', margin: '0 auto 8px', width: queryModes ?  '1200px' : '1082px'}">

            <el-radio v-if="item.show" :label="item.value" v-for="(item, index) in firstList" :key="index">{{item.text}}</el-radio>
          </el-radio-group>
          <div class="input_search" :style="{margin: queryModes ? '210px auto 0' : '0 auto',  width: queryModes ?  '1200px' : '1082px'}" style="display: flex">
            <div :style="{width: !queryModes ? '940px' : '1197px' }" :class="['input-single',{'borderBlue':inputFocus}]">
              <!--            <el-select v-model="countryVal" placeholder="请选择" class="category">-->
              <!--              <el-option v-for="item in countryList" :key="item.value" :label="item.label" :value="item.value">-->
              <!--              </el-option>-->
              <!--            </el-select>-->

              <div class="input_box">
                <el-input class="input-con" ref="searchInput" :placeholder="files == ''? $t(`PQ.searchInput[0]`) : ''" v-model="searchText"
                          @keyup.enter.native="searchFirstFn" @input="inputFn" @focus="inputFocus=true" @blur="inputFocus=false">
                  <template class="prepend_input"  slot="prepend">
                    <div @click.stop="rotate">
                      <img v-if="stateChange && stateChange.length" style="vertical-align: middle;width: 36px;" :src="nationalFlag" alt="">
                      <i  :class="{rotate:isRotate == true}" style="color: #ffffff;margin-left: 5px" class="el-icon-caret-bottom"></i>
                    </div>
                  </template>
                </el-input>
              </div>
              <el-upload  action="" ref="upload" class="left-upload camera2" :file-list="fileList" :show-file-list="true" list-type="picture"
                          :on-change="uploadChangeFn" :on-remove="handleRemove" :auto-upload="false"
                          :class="{vh:queryModes=='text'}">
                <div class="camera-box">
                  <img src="@/assets/images/query/camera1_2.png" class="camera-btn camera-btn1" />
                  <img src="@/assets/images/query/camera2.png" class="camera-btn camera-btn2" />
                </div>
              </el-upload>
              <p class="query-btn" @click="searchFirstFn"></p>
            </div>
            <!--            <el-button v-if="!queryModes" class="search_btn" style="width: 100px;margin-left: 15px"  @click="category">类别</el-button>-->
            <!--            <el-button v-if="!queryModes" class="search_btn" style="margin-left: 15px"  @click="combinedQuery">-->
            <!--              {{$t(`PQ.searchInput[1]`)}}</el-button>-->
          </div>
        </section>
      </div>

      <!--
      等待优化,
      合并维也纳分类,尼斯分类,共用一个弹窗
      -->
      <el-collapse-transition>
        <div
          class="high-grade-query cn-new"
          v-show="combinedquery && !queryModes"
        >
          <div class="toFast" @click="swichFastHigh(0)" >
            <i class="el-icon-arrow-right"></i>
            <span>{{$t('query_lang.fastQuery')}}</span>
          </div>
          <!--查询方向-->
          <!--        <div class="query-item" >-->
          <!--          <p class="title" style="padding-left: 15px;border: 1px solid #d8dce5;box-sizing: border-box">查询方向</p>-->
          <!--          <div class="container">-->
          <!--            <el-select v-model="value"  placeholder="请选择">-->
          <!--              <el-option-->
          <!--                v-for="item in firstList"-->
          <!--                :key="item.value"-->
          <!--                :label="item.text"-->
          <!--                :value="item.value">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </div>-->
          <!--        </div>-->
          <!-- 高级查询改版之后 固定查询下拉部分end -->
          <!-- 高级查询改版之后 动态新增下拉数据部分 start-->
          <div class="query-item" v-for="(item,index) in query_Items" :key="index">
            <p class="title">
              <el-select
                v-model="item.tmParams"
                :placeholder="$t(`PQ.searchInput[2]`)"
                @change="changeVal(item.tmParams,index)"
              >
                <el-option
                  v-for="items in item.options"
                  :key="items.value"
                  :label="items.label"
                  :value="items.value"
                  :disabled="items.disabled"
                ></el-option>
              </el-select>
            </p>
            <div class="container">
              <el-input
                v-model="item.input"
                style="width: 520px;" :disabled="true"
                v-if="!(item.tmParams=='SBMC'||
           item.tmParams=='SBTP'||
           item.tmParams=='TXYSBM'||
           item.tmParams=='LB'||
           item.tmParams=='SQH'||
           item.tmParams=='ZCH'||
           item.tmParams=='SQRMC'||
           item.tmParams=='SQRDZ'||
           item.tmParams=='SQR'||
           item.tmParams=='CSGGR'||
           item.tmParams=='ZCR'||
           item.tmParams=='ZYQDQR'||
           item.tmParams=='DLJG'||
           item.tmParams=='ZCGGR'||
           item.tmParams=='YXQR'||
           item.tmParams=='SBZT'||
           item.tmParams=='SFYX'||
           item.tmParams=='SFGYSB')"
              ></el-input>

              <!--商标名称-->
              <!--            :disabled="!!imgUplad"-->
              <el-input
                v-if="item.tmParams=='SBMC'"
                @keyup.enter.native="highGradeQuery"
                class="container el-input-long-officialt"
                v-model.trim="queryItem.tradeMarkText.value"

              ></el-input>
              <!--有图片,或者有注册号的时候,禁止切换查询模式-->
              <!--            :disabled="!!imgUplad||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"-->
              <el-radio-group
                v-model="queryMode.choosed"
                class="queryMode"
                @change="queryWayFn"
                v-if="item.tmParams=='SBMC'"

              >
                <el-radio label="similar">{{$t('query_lang.Similar_search')}}</el-radio>
                <el-radio label="match">{{$t('query_lang.Contained_search')}}</el-radio>
                <el-radio label="term">{{$t('query_lang.Identical_search')}}</el-radio>
              </el-radio-group>
              <!--有图片,或者有注册号的时候,禁止切换查询模式-->
              <!-- 商标图形 -->
              <!--            :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value||!queryData.isCanUploadImg"-->
              <progressp :psMsg.sync="flagdata" ref="child"></progressp>
              <el-upload
                drag
                action=""
                multiple
                class="upload-drag"
                :class="{uploadDisabled:!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value||!queryData.isCanUploadImg}"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="uploadFn"

                v-if="item.tmParams=='SBTP'"
              >
                <img :src="imgDefault" class="upload-btn" v-if="!imgUplad">
                <img :src="(imgUplad)" v-if="imgUplad" class="upload-img" referrerPolicy="no-referrer">
                <p v-if="imgUplad" class="delete" @click.stop="deleteImg">{{$t("update_lang.btn_delete")}}</p>
                <p v-if="!imgUplad">{{$t(`PQ.searchInput[3]`)}}</p>
                <div class="el-upload-disabled-shade"></div>
              </el-upload>
              <!-- 商标图形 -->
              <!--图形要素编码-->
              <!--            :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"-->
              <div class="choose" v-if="item.tmParams=='TXYSBM'">
                <el-input
                  class="leftText el-input-long-officialt"
                  :placeholder="$t('follow_up_FE_lang.enter_fomat_vienna')"
                  v-model="queryItem.patternElement.value"
                  @blur="viennaInput"

                ></el-input>
                <span style="font-size: 0;width: 0;">{{viennaChoosedStr}}</span>
                <!--<span class="leftText">{{viennaChoosedStr}}</span>-->
                <!--<span
                  class="rightText"
                  @click="!imgUplad?ViennaVisible=true:ViennaVisible=false"
                  :style="{cursor:!imgUplad?'pointer':'not-allowed'}"
                >{{$t('query_lang.please_choose')}}</span>-->
                <!--              :disabled="!!queryItem.tradeMarkText.value||!!queryItem.applicationNumber.value||!!queryItem.registerNumber.value"-->
                <el-button
                  class="el-long-white-button rightText"
                  @click="ViennaVisible=true"

                >{{$t(`PQ.searchInput[2]`)}}</el-button>
              </div>
              <!--类别-->
              <!--            :disabled="!!queryItem.applicationNumber.value"-->
              <div class="choose" v-if="item.tmParams=='LB'">
                <el-input
                  class="leftText el-input-long-officialt"
                  :placeholder="$t('follow_up_FE_lang.enter_fomat_nice')"
                  v-model="queryItem.classify.value"
                  @blur="classifyInput"

                ></el-input>
                <span style="font-size: 0;width: 0;">{{niceChoosedStr}}</span>
                <!-- <span class="leftText">{{niceChoosedStr}}</span> -->
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-button
                  class="el-long-white-button rightText"
                  @click="niceVisible=true"

                >{{$t(`PQ.searchInput[2]`)}}</el-button>
              </div>
              <!--申请号/注册号-->
              <!--            :disabled="!!imgUplad||!!queryItem.tradeMarkText.value||!!queryItem.patternElement.value"-->
              <el-input
                v-if="item.tmParams=='SQH'"
                @keyup.enter.native="highGradeQuery"
                class="container el-input-long-officialt"
                :placeholder="$t('follow_up_FE_lang.enter_fomat_no')"
                v-model.trim="queryItem.applicationNumber.value"
                @input="queryMode.choosed='term'"

              ></el-input>
              <!--            :disabled="!!imgUplad||!!queryItem.tradeMarkText.value||!!queryItem.patternElement.value"-->
              <el-input
                v-if="item.tmParams=='ZCH'"
                @keyup.enter.native="highGradeQuery"
                class="container el-input-long-officialt"
                :placeholder="$t('follow_up_FE_lang.enter_fomat_reg_no')"
                v-model.trim="queryItem.registerNumber.value"
                @input="queryMode.choosed='term'"

              ></el-input>
              <!--申请人名称-->
              <template >
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <!--2018-12-29邱峰说可以  放开这个判断  对应禅道任务1138-->
                <el-input
                  v-if="item.tmParams=='SQRMC'"
                  @keyup.enter.native="highGradeQuery"
                  class="container el-input-long-officialt"
                  v-model.trim="queryItem.tmApplicant.value"

                ></el-input>
                <!--申请人地址-->
                <!--              :disabled="queryItem.tmApplicant.value==''||!!queryItem.applicationNumber.value"-->
                <el-input
                  v-if="item.tmParams=='SQRDZ'"
                  @keyup.enter.native="highGradeQuery"
                  class="container el-input-long-officialt"

                  v-model.trim="queryItem.tmAddress.value"
                ></el-input>
              </template>

              <!--申请日-->
              <!--            :disabled="!!queryItem.applicationNumber.value"-->
              <div class="container" >
                <div class="date" v-if="item.tmParams=='SQR'">
                  <el-date-picker

                    v-model="queryItem.appDate.beginValue"
                    type="date"
                    class="el-date-editor-long-officialt"
                    :placeholder="$t('query_lang.chooseDate')"
                  ></el-date-picker>
                  <span class="to">{{$t('query_lang.to')}}</span>
                  <!--                :disabled="!!queryItem.applicationNumber.value"-->
                  <el-date-picker

                    v-model="queryItem.appDate.endValue"
                    type="date"
                    class="el-date-editor-long-officialt"
                    :placeholder="$t('query_lang.chooseDate')"
                  ></el-date-picker>
                </div>
              </div>
              <!--申请日-->
              <!-- 商标状态 -->
              <div class="container" >
                <div class="date" v-if="item.tmParams=='SBZT'">
                  <!-- 不同国家显示不同商标状态 -->
                  <el-select v-model="queryItem.status.value" v-if="queryData.countryStatusVuex==0" multiple>
                    <el-option
                      v-for="item in queryData.statusList.TM5_list"
                      :key="item.index"
                      :label="item.zhName"
                      :value="item.index"
                    ></el-option>
                  </el-select>
                  <el-select v-model="queryItem.status.value" v-if="queryData.countryStatusVuex==1" multiple>
                    <el-option
                      v-for="item in queryData.statusList.OTHER_list"
                      :key="item.index"
                      :label="item.zhName"
                      :value="item.index"
                    ></el-option>
                  </el-select>
                  <el-select v-model="queryItem.status.value" v-if="queryData.countryStatusVuex==2" multiple>
                    <el-option
                      v-for="item in queryData.statusList.COMBINE_list"
                      :key="item.index"
                      :label="item.zhName"
                      :value="item.index"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <!-- 商标状态 -->
              <!-- 是否共有商标 -->
              <el-radio-group
                v-model="queryItem.istotal.value"
                class="queryMode"
                v-if="item.tmParams=='SFGYSB'"
              >
                <el-radio label="yes">{{$t(`PQ.fild[0]`)}}</el-radio>
                <el-radio label="no">{{$t(`PQ.fild[1]`)}}</el-radio>
              </el-radio-group>
              <!-- 是否共有商标 -->
              <!-- 是否有效 -->
              <el-radio-group
                v-model="queryItem.active.value"
                class="queryMode"
                v-if="item.tmParams=='SFYX'"
              >
                <el-radio label="1">{{$t(`PQ.fild[2]`)}}</el-radio>
                <el-radio label="0">{{$t(`PQ.fild[3]`)}}</el-radio>
              </el-radio-group>
              <!-- 是否有效 -->
              <!-- 初审公告日 -->
              <div class="container" v-if="item.tmParams=='CSGGR'">
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <div class="date">
                  <el-date-picker

                    v-model="queryItem.trialDate.beginValue"
                    type="date"
                    class="el-date-editor-long-officialt"
                    :placeholder="$t('query_lang.chooseDate')"
                  ></el-date-picker>
                  <span class="to">{{$t('query_lang.to')}}</span>
                  <!--                :disabled="!!queryItem.applicationNumber.value"-->
                  <el-date-picker

                    v-model="queryItem.trialDate.endValue"
                    type="date"
                    class="el-date-editor-long-officialt"
                    :placeholder="$t('query_lang.chooseDate')"
                  ></el-date-picker>
                </div>
              </div>
              <!-- 初审公告日 -->
              <!--注册日-->
              <div class="date" v-if="item.tmParams=='ZCR'">
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-date-picker

                  v-model="queryItem.regDate.beginValue"
                  type="date"
                  class="el-date-editor-long-officialt"
                  :placeholder="$t('query_lang.chooseDate')"
                ></el-date-picker>
                <span class="to">{{$t('query_lang.to')}}</span>
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-date-picker

                  v-model="queryItem.regDate.endValue"
                  type="date"
                  class="el-date-editor-long-officialt"
                  :placeholder="$t('query_lang.chooseDate')"
                ></el-date-picker>
              </div>
              <!--注册日-->
              <!-- 注册公告日 -->
              <div class="date" v-if="item.tmParams=='ZCGGR'">
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-date-picker

                  v-model="queryItem.ggDate.beginValue"
                  type="date"
                  class="el-date-editor-long-officialt"
                  :placeholder="$t('query_lang.chooseDate')"
                ></el-date-picker>
                <span class="to">{{$t('query_lang.to')}}</span>
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-date-picker

                  v-model="queryItem.ggDate.endValue"
                  type="date"
                  class="el-date-editor-long-officialt"
                  :placeholder="$t('query_lang.chooseDate')"
                ></el-date-picker>
              </div>
              <!-- 注册公告日 -->
              <!-- 优先权日 -->
              <div class="date" v-if="item.tmParams=='YXQR'">
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-date-picker

                  v-model="queryItem.priorityDate.beginValue"
                  type="date"
                  class="el-date-editor-long-officialt"
                  :placeholder="$t('query_lang.chooseDate')"
                ></el-date-picker>
                <span class="to">{{$t('query_lang.to')}}</span>
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-date-picker

                  v-model="queryItem.priorityDate.endValue"
                  type="date"
                  class="el-date-editor-long-officialt"
                  :placeholder="$t('query_lang.chooseDate')"
                ></el-date-picker>
              </div>
              <!-- 优先权日 -->
              <!--专用权到期日-->
              <div class="container" v-if="item.tmParams=='ZYQDQR'">
                <div class="date">
                  <!--                :disabled="!!queryItem.applicationNumber.value"-->
                  <el-date-picker

                    v-model="queryItem.privateDate.beginValue"
                    type="date"
                    class="el-date-editor-long-officialt"
                    :placeholder="$t('query_lang.chooseDate')"
                  ></el-date-picker>
                  <span class="to">{{$t('query_lang.to')}}</span>
                  <!--                :disabled="!!queryItem.applicationNumber.value"-->
                  <el-date-picker

                    v-model="queryItem.privateDate.endValue"
                    type="date"
                    class="el-date-editor-long-officialt"
                    :placeholder="$t('query_lang.chooseDate')"
                  ></el-date-picker>
                </div>
              </div>
              <!--专用权到期日-->
              <!--代理机构-->
              <div class="container" v-if="item.tmParams=='DLJG'">
                <!--              :disabled="!!queryItem.applicationNumber.value"-->
                <el-input
                  @keyup.enter.native="highGradeQuery"
                  class="container el-input-long-officialt"

                  v-model.trim="queryItem.tmAgent.value"
                ></el-input>
              </div>
              <!--代理机构-->
            </div>
            <i
              class="el-icon-delete"
              @click="delInput(item.tmParams,index)"
              style="color:black;cursor:pointer;position:absolute;right:-20px;top:15px;transform:scale(1.2);"
            ></i>
          </div>
          <el-button @click="addInput" :disabled="isAddInput" class="return-btn el-long-white-button">+ {{$t(`PQ.fild[4]`)}}</el-button>
          <!-- 高级查询改版之后 动态新增下拉数据部分 end-->

          <div class="buttons">
            <!--          <el-button-->
            <!--            class="query-btn el-long-defalut-button"-->
            <!--            @click="highGradeQuery"-->
            <!--            :disabled="onlyVipCanQuery&&!noReset.isPaied"-->
            <!--          >{{$t('query_lang.search')}}</el-button>-->
            <el-button
              class="return-btn el-long-white-button"
              @click="reset_Fn"
            >{{$t('follow_up_FE_lang.reset')}}</el-button>
          </div>

          <!--维也纳分类-->
          <el-dialog :visible.sync="ViennaVisible" class="long-dialog-old" @open="openVienna">
            <div class="long-inner long-inner-viena">
              <viennaCodezu @closeViennaFn="ViennaVisible=false" ref="viennaCodezuRef"></viennaCodezu>
            </div>
          </el-dialog>
          <!--尼斯分类-->
          <el-dialog :visible.sync="niceVisible" class="long-dialog-old">
            <!--0124注释掉,暂时不用此模式,改用同快速查询一样的模式-->
            <div class="long-inner" v-if="false">
              <!--{{vienna.mixture}}-->
              <div class="top">
                <i class="el-icon-warning"></i>
                <span>{{$t(`PQ.fild[5]`)}}:&nbsp;&nbsp;{{$t(`PQ.fild[6]`)}}</span>
              </div>
              <div class="middle nice-style">
                <el-checkbox-group v-model="niceChoosedArr">
                  <ul class="first-ul" v-if="true">
                    <li class="first-li" v-for="(item,index) in niceAll">
                      <p class="first-p" @click="isOpenFn(item)">{{item.categoryName.slice(0,5)}}</p>
                      <ul class="second-ul" v-if="item.isOpen">
                        <li class="second-li" v-for="(item2,index2) in item.children">
                          <p class="second-p" @click="isOpenFn(item2)">
                            <el-checkbox
                              @change="viennaChange"
                              :max="5"
                              :label="item2.goodsChName"
                            >{{item2.goodsChName}}</el-checkbox>
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </el-checkbox-group>
              </div>
              <div class="bottom">
                <el-button class="viennaConfirm el-long-defalut-button" @click="niceConfirm">{{$t(`PQ.queryModes[1]`)}}</el-button>
                <el-button class="viennaClose el-long-white-button" @click="niceClose">{{$t(`PQ.fild[8]`)}}</el-button>
              </div>
            </div>
            <div class="long-inner">
              <classify @closeFn="niceVisible=false" class="high-classify"></classify>
            </div>
          </el-dialog>
        </div>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-show="queryConditon || queryModes" class="query-second-conditon">
          <div :style="{'borderBottom':!queryModes ? '2px solid rgba(91,125,246,0.52)' : '', 'width': !queryModes ? '1082px' : '1200px'}" style="margin: 0 auto;padding-bottom: 20px" :class="{ 'dib-vam-category' : queryModes ? true : false}" class="item dib-vam-fz0">
            <p :style="{color: queryModes?  '#1A3DBF' : '#333333', width: !queryModes ? '1083' : '1200px'}" class="category">{{queryModes?  $t(`PQ.fild[9]`) : $t(`PQ.fild[10]`)}}</p>
            <div style="display: flex">
              <div :style="{color: '#333333',fontSize: '15px',marginTop: '14px',paddingLeft: '33px'}" v-if="queryModes">
                {{$t(`PQ.accordion[3]`)}}：</div>
              <ul v-if="niceList" class="dib-vam-fz0" :style="{width: '1093px', paddingLeft: !queryModes ? '25px' : ''}" :class="{isOpen:moreObj.category || !queryModes}">
                <el-tooltip popper-class="tooltip_query" v-for="(item,index) in niceList"  :key="index" effect="light" :content="item.typeName" placement="top">
                  <li  :style="{marginLeft: (index % 3 === 0 && index !== 0  && index !== 12 && index !== 24 && index !== 36 ? '47px!important' : index !== 0  && index !== 12 && index !== 24 && index !== 36 ? '20px!important' : ''), cursor: 'pointer'} " class="li-category" :class="{isCheck:item.isCheck}"
                       @click="item.isCheck=!item.isCheck">
                    <span v-if="isCN">{{item.name}}-{{typeName(item.name)}}</span>
                    <span v-else>{{item.name}}</span>
                    <!--                    <p class="check-circle" v-show="item.isCheck"><img src="@/assets/images/query/select.png" /></p>-->
                  </li>
                </el-tooltip>
              </ul>
            </div>
            <!--            <div v-if="queryModes" style="display: flex;align-items: baseline;min-height: 40px;padding-left: 33px">-->
            <!--              <div style="color: #555555;font-size: 15px">{{$t(`PQ.fild[11]`)}}</div>-->
            <!--              <ul v-if="selectedCountries && selectedCountries.length > 0" class="dib-selecte-country" >-->
            <!--                <li :class="[{active:choiceCountry.includes(item.name)},'li-selecte-country',{actives: !isCN}]" :style="{cursor: item.value == 0 ? 'no-drop' : 'pointer'}" @click.stop="countrySelect(item.name,item.value)"  v-for="(item,index) in selectedCountries"  :key="index" style="cursor: pointer;display: flex"-->
            <!--                >-->
            <!--                  <span >{{item.countryName}}({{item.value}})</span>-->
            <!--                  <i v-if="selectedCountries && selectedCountries.length > 1" @click.stop="delCountry(item.name)" class="el-icon-close"></i>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </div>-->

            <p class="more" v-if="queryModes" @click="moreFn('category')">{{$t(`PQ.queryModes[0]`)}}<i :class="{up:moreObj['category']}"></i></p>

            <div v-if="queryModes" class="selecteBtns btns">
              <span class="btn" @click="searchFirstFn">{{$t(`PQ.queryModes[1]`)}}</span>
              <span class="btn active" @click="resetSizer">{{$t(`PQ.queryModes[2]`)}}</span>
              <span class="btn active" @click="goBack">{{$t(`PQ.queryModes[3]`)}}</span>
            </div>
          </div>
          <div v-show="queryConditon" v-if="!queryModes" class="selecteBtn btns">
            <el-checkbox v-model="allChecked">{{$t(`PQ.queryModes[7]`)}}</el-checkbox>
            <span class="btn active" @click="resetSizer">{{$t(`PQ.queryModes[2]`)}}</span>
          </div>
        </div>
      </el-collapse-transition>

      <!--    数据库-->
      <el-card @click.native.stop="blockClicks"
               :style="{top:queryModes?'178px':'383px;',position:queryModes?'fixed':'absolute'}"
               :class="['boxCard',{'actives':!isCN},{'active':queryModes}]" v-if="boxShow">
        <!--    数据库-->
        <div class="index_1">
          <el-checkbox @change="database" v-model="checked">{{$t(`PQ.queryModes[6]`)}}</el-checkbox>
          <div>
            <el-button @click.stop="selectAll" type="primary" size="mini">{{$t(`PQ.queryModes[7]`)}}</el-button>
            <el-button @click.stop="cancelAll" type="primary" size="mini">{{$t(`PQ.queryModes[8]`)}}</el-button>
          </div>
        </div>

        <!--      亚洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="asiaIsIndeterminate" v-model="asiaCheckAll" @change="handleCheckAllChange">{{$t(`PQ.country[0]`)}}  ({{index1}}/14)</el-checkbox>
          </div>
          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="asia" @change="handleCheckedCitiesChange">
              <el-checkbox   class="checkbox" v-for="(item,index) in asiaCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--      欧洲-->
        <div style="display: flex;margin-top: 10px">
          <div>
            <el-checkbox style="width: 100px" :indeterminate="europeIsIndeterminate" v-model="europeCheckAll" @change="handleCheckAllChanges">{{$t(`PQ.country[1]`)}}  ({{index2}}/19)</el-checkbox>
          </div>
          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="europe" @change="handleCheckedCitiesChanges">
              <el-checkbox  class="checkbox" v-for="(item,index) in europeCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--      北美洲-->
        <div style="display: flex;margin-top: 10px">
          <div >
            <el-checkbox style="width: 100px" :indeterminate="americaIsIndeterminate" v-model="americaCheckAll" @change="handleCheckAllChangess">{{$t(`PQ.country[2]`)}}  ({{index3}}/3)</el-checkbox>
          </div>
          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="america" @change="handleCheckedCitiesChangess">
              <el-checkbox  class="checkbox" v-for="(item,index) in americaCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--      南美洲-->
        <div style="display: flex;margin-top: 10px">
          <div >
            <el-checkbox style="width: 100px" :indeterminate="southIsIndeterminate" v-model="southCheckAll" @change="handleCheckAllChangesss">{{$t(`PQ.country[3]`)}}  ({{index4}}/3)</el-checkbox>
          </div>
          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="south" @change="handleCheckedCitiesChangesss">
              <el-checkbox  class="checkbox" v-for="(item,index) in southCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--      非洲-->
        <div style="display: flex;margin-top: 10px">
          <div >
            <el-checkbox style="width: 100px" :indeterminate="africaIsIndeterminate" v-model="africaCheckAll" @change="handleCheckAllChangessss">{{$t(`PQ.country[4]`)}}  ({{index5}}/1)</el-checkbox>
          </div>
          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="africa" @change="handleCheckedCitiesChangessss">
              <el-checkbox  class="checkbox" v-for="(item,index) in africaCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--      大洋洲-->
        <div style="display: flex;margin-top: 10px">
          <div >
            <el-checkbox style="width: 100px" :indeterminate="oceaniaIsIndeterminate" v-model="oceaniaCheckAll" @change="handleCheckAllChangesssss">{{$t(`PQ.country[5]`)}}  ({{index6}}/2)</el-checkbox>
          </div>
          <div style="margin-left: 50px">
            <el-checkbox-group class="checkbox_group" v-model="oceania" @change="handleCheckedCitiesChangesssss">
              <el-checkbox  class="checkbox" v-for="(item,index) in OceaniaCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <!--        知识产权组织-->
        <div style="display: flex;margin-top: 10px">
          <div >
            <el-checkbox style="width: 100px" :indeterminate="propertyIndeterminate" v-model="propertyCheckAll" @change="handleCheckAllChangessssse">{{$t(`PQ.country[6]`)}}  ({{index7}}/1)</el-checkbox>
          </div>
          <div style="margin-left: 207px">
            <el-checkbox-group class="checkbox_group" v-model="property" @change="handleCheckedCitiesChangessssse">
              <el-checkbox  class="checkbox" v-for="(item,index) in propertyOrganizationCity"  :label="item.code" :key="item.city">
                <img class="checkbox_img" :src="item.ingSrc" alt="">
                <span style="margin-left: 12px">{{item.city}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>


        <div style="display: flex;float: right">
          <el-button style="margin-bottom: 31px;" class="query-btns" @click="searchFirstFn()"></el-button>
          <el-button v-if="isCN" style="float: right;width: 98px;height: 36px;font-size: 16px;padding: 0" type="primary" @click="apply">{{$t(`PQ.country[7]`)}}</el-button>
        </div>

      </el-card>

      <!--      <section class="condtion" >-->
      <!--        <ul class="dib-vam-fz0 first-condtion">-->
      <!--          <li v-for="(item,index) in firstList" v-if="item.show" :class="{active:index==firstChoosed}" @click="firstChange(index)">-->
      <!--            {{item.text}}-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--&lt;!&ndash;        <div class="second-conditon">&ndash;&gt;-->
      <!--&lt;!&ndash;          <div class="item dib-vam-fz0">&ndash;&gt;-->
      <!--&lt;!&ndash;            <p class="title">申请类别：</p>&ndash;&gt;-->
      <!--&lt;!&ndash;            <ul class="dib-vam-fz0" :class="{isOpen:moreObj.category}">&ndash;&gt;-->
      <!--&lt;!&ndash;              <el-tooltip popper-class="tooltip_query"  v-for="(item,index) in niceList"  :key="index" effect="light" :content="item.typeName" placement="bottom">&ndash;&gt;-->
      <!--&lt;!&ndash;                <li :style="{marginLeft: (index % 3 == 0 ? '47px!important' : '20px!important'), cursor: 'pointer'} " class="li-category" :class="{isCheck:item.isCheck}"&ndash;&gt;-->
      <!--&lt;!&ndash;                    @click="item.isCheck=!item.isCheck">&ndash;&gt;-->
      <!--&lt;!&ndash;                  <span>{{item.name}}</span>&ndash;&gt;-->
      <!--&lt;!&ndash;&lt;!&ndash;                  <p class="check-circle" v-show="item.isCheck"><img src="@/assets/images/query/select.png" /></p>&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;                </li>&ndash;&gt;-->
      <!--&lt;!&ndash;              </el-tooltip>&ndash;&gt;-->
      <!--&lt;!&ndash;            </ul>&ndash;&gt;-->
      <!--&lt;!&ndash;            <p class="more" @click="moreFn('category')">更多<i :class="{up:moreObj['category']}"></i></p>&ndash;&gt;-->
      <!--&lt;!&ndash;          </div>&ndash;&gt;-->
      <!--&lt;!&ndash;          <div class="btns">&ndash;&gt;-->
      <!--&lt;!&ndash;            <span class="btn" @click="confirmSizer">确认</span>&ndash;&gt;-->
      <!--&lt;!&ndash;            <span class="btn active" @click="resetSizer">重置</span>&ndash;&gt;-->
      <!--&lt;!&ndash;          </div>&ndash;&gt;-->
      <!--&lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      </section>-->
      <section class="blank" v-if="queryModes"></section>

      <section :accordion="false" class="result" style="display: flex;justify-content: flex-end" v-if="queryMode&&resultList&&resultList.length || resultList&&resultList.length&&isReport == 'CN'">
        <!--        <div class="screen left-secondQueryCondition" >-->
        <!--        </div>-->
        <!--        <div :style="{position:isScroll && resultList.length > 1 ? 'fixed' : 'absolute',top: isScroll ? '166px' : '' }"  class="screen left-secondQueryCondition" >-->
        <!--          <el-form class="screen_form" ref="asideForm" :model="asideForms" @submit.native.prevent>-->
        <!--            &lt;!&ndash;            <el-collapse>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <el-collapse-item title="图样" class="unfold" name="picture" >&ndash;&gt;-->
        <!--            &lt;!&ndash;                <div class="imgBoxDiv">&ndash;&gt;-->
        <!--            &lt;!&ndash;                  <el-upload&ndash;&gt;-->
        <!--            &lt;!&ndash;                    drag&ndash;&gt;-->
        <!--            &lt;!&ndash;                    action&ndash;&gt;-->
        <!--            &lt;!&ndash;                    multiple&ndash;&gt;-->
        <!--            &lt;!&ndash;                    class="upload-drag"&ndash;&gt;-->
        <!--            &lt;!&ndash;                    :auto-upload="false"&ndash;&gt;-->
        <!--            &lt;!&ndash;                    :show-file-list="false"&ndash;&gt;-->
        <!--            &lt;!&ndash;                    :on-change="uploadFn"&ndash;&gt;-->
        <!--            &lt;!&ndash;                  >&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <span>点击图片重新上传</span>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <img :src=queryData.littlePicUrl />&ndash;&gt;-->
        <!--            &lt;!&ndash;                  </el-upload>&ndash;&gt;-->
        <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                <el-button&ndash;&gt;-->
        <!--            &lt;!&ndash;                  style="&ndash;&gt;-->
        <!--            &lt;!&ndash;                  margin: 8px 66px;&ndash;&gt;-->
        <!--            &lt;!&ndash;                  padding: 5px 5px;&ndash;&gt;-->
        <!--            &lt;!&ndash;                  color: #637a91;&ndash;&gt;-->
        <!--            &lt;!&ndash;                  border-color: #637a91;&ndash;&gt;-->
        <!--            &lt;!&ndash;                "&ndash;&gt;-->
        <!--            &lt;!&ndash;                  @click="reConditionQuery"&ndash;&gt;-->
        <!--            &lt;!&ndash;                >筛选</el-button&ndash;&gt;-->
        <!--            &lt;!&ndash;                >&ndash;&gt;-->
        <!--            &lt;!&ndash;              </el-collapse-item>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <el-collapse-item title="商标名称" class="unfold" name="text">&ndash;&gt;-->
        <!--            &lt;!&ndash;                <div class="textBoxDiv">&ndash;&gt;-->
        <!--            &lt;!&ndash;                  <el-input :value="queryData.tradeMarkText" @change="changeInpText" clearable @keyup.enter.native.stop="reConditionQuery"></el-input>&ndash;&gt;-->
        <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                <el-button&ndash;&gt;-->
        <!--            &lt;!&ndash;                  style="&ndash;&gt;-->
        <!--            &lt;!&ndash;                  margin: 20px 0 0 11px;&ndash;&gt;-->
        <!--            &lt;!&ndash;                  padding: 5px 5px;&ndash;&gt;-->
        <!--            &lt;!&ndash;                  color: #637a91;&ndash;&gt;-->
        <!--            &lt;!&ndash;                  border-color: #637a91;&ndash;&gt;-->
        <!--            &lt;!&ndash;                "&ndash;&gt;-->
        <!--            &lt;!&ndash;                  @click="reConditionQuery"&ndash;&gt;-->
        <!--            &lt;!&ndash;                >筛选</el-button>&ndash;&gt;-->
        <!--            &lt;!&ndash;              </el-collapse-item>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </el-collapse>&ndash;&gt;-->

        <!--            <el-collapse accordion>-->
        <!--              <el-collapse-item  class="unfold">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.accordion[0]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.applicant" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="item in responseData.applicantList"-->
        <!--                        :label="item.name"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :key="item.name"-->
        <!--                      >{{ item.name+'('+item.value+')'}}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('applicant')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->


        <!--              <el-collapse-item class="unfold">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.accordion[3]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.niceList" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="(item,index) in responseData.intclsList"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :label="item.name"-->
        <!--                        :key="index"-->
        <!--                      >{{ item.name+'('+item.value +')' }}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('niceList')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->

        <!--              <el-collapse-item  class="unfold">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.accordion[4]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.appYear" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="(item,index) in responseData.appDateList"-->
        <!--                        :label="item.name"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :key="index"-->
        <!--                      >{{ item.name+'('+item.value+')'}}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('appYear')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->

        <!--              <el-collapse-item class="unfold">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.details_item[2]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.regYear" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="item in responseData.trialDateList"-->
        <!--                        :label="item.name"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :key="item.index"-->
        <!--                      >{{ item.name+'('+item.value+')'}}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('regYear')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->

        <!--              <el-collapse-item  class="unfold">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.accordion[6]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.active" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="item in responseData.activeList"-->
        <!--                        :label="item.name"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :key="item.name"-->
        <!--                      >{{ (item.name == '0' ? $t(`PQ.fild[3]`) : $t(`PQ.fild[2]`))+'('+item.value+')' }}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('active')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->

        <!--              <el-collapse-item class="unfold">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.accordion[9]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.tmstatuscode" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="item in responseData.tmstatuscodeList"-->
        <!--                        :label="item.name"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :key="item.name"-->
        <!--                      >{{ item.statusName+'('+item.value+')'}}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('tmstatuscode')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->

        <!--              <el-collapse-item class="unfold" v-if="responseData.agentList && responseData.agentList.length">-->
        <!--                <template slot="title" style="text-align: center">-->
        <!--                  <i class="header-icon"></i>{{$t(`PQ.accordion[10]`)}}-->
        <!--                </template>-->
        <!--                <el-form-item style="margin-bottom: 0px">-->
        <!--                  <el-checkbox-group v-model="asideForms.agent" size="small">-->
        <!--                    <div class="checkBoxDiv">-->
        <!--                      <el-checkbox-->
        <!--                        v-for="item in responseData.agentList"-->
        <!--                        :label="item.name"-->
        <!--                        style="margin: 0 0 0 10px;display:block;"-->
        <!--                        :key="item.name"-->
        <!--                      >{{ item.name+'('+item.value+')'}}</el-checkbox>-->
        <!--                    </div>-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="conditionalQuery()"-->
        <!--                    >{{$t(`PQ.accordion[1]`)}}</el-button-->
        <!--                    >-->
        <!--                    <el-button-->
        <!--                      style="-->
        <!--                      margin: 20px 0 0 11px;-->
        <!--                      padding: 5px 5px;-->
        <!--                      color: #637a91;-->
        <!--                      border-color: #637a91;-->
        <!--                    "-->
        <!--                      @click="clearCondition('agent')"-->
        <!--                    >{{$t(`PQ.accordion[2]`)}}</el-button-->
        <!--                    >-->
        <!--                  </el-checkbox-group>-->
        <!--                </el-form-item>-->
        <!--              </el-collapse-item>-->
        <!--            </el-collapse>-->

        <!--            &lt;!&ndash;            <div style="width: 100%;text-align: center;margin-top: 60px">&ndash;&gt;-->
        <!--            &lt;!&ndash;              <el-button style="width: 198px" @click="conditionalQuery()" type="primary">筛选</el-button>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->

        <!--          </el-form>-->
        <!--        </div>-->

        <div class="screen_content">
          <div class="table-icon">
            <div class="switch-style-container dib-vam-fz0">
              <span class="left" :class="{active:!switchStyle}" @click="switchStyle=false;calculation()"></span>
              <span class="right" :class="{active:switchStyle}" @click="switchStyle=true;calculation()"></span>
              <!-- <span class="download_icon" @click="downEvent"></span> -->

              <template v-if="isReport == 'CN' && value == 2">
                <el-popover
                  style="margin-top: 4px;margin-left: 30px"
                  placement="bottom-start"
                  title=""
                  width="100px"
                  trigger="hover"
                  :content="$t(`PQ.accordion[11]`)">
                  <img @click="jumpReport" style="cursor: pointer" src="@/assets/images/query/report.png" class="" slot="reference"/>
                </el-popover>
              </template>
            </div>
          </div>
          <li v-if="noData && resultList.length == 0" :style="{height:!switchStyle?'360px':'360px'}"  class="dib-vam-fz0" style="height: 360px;text-align: center;background: #FFFFFF;display: flex;justify-content: center;align-items: center">
            <div>
              <img src="@/assets/images/query/noData2.png" alt="">
            </div>
          </li>
          <!--表格版-->
          <ul :class="['table',{'pq_active': !isCN}]"  v-if="!switchStyle">
            <!--          <li v-if="noData" class="dib-vam-fz0" style="height: 350px;text-align: center">-->
            <!--            <div>-->
            <!--              <img src="@/assets/images/query/noData.png" alt="">-->
            <!--              <p style="font-size: 20px;color: rgb(102, 102, 102);">暂无结果，以下为其他类别结果供参考</p>-->
            <!--            </div>-->
            <!--          </li>-->
            <li class="dib-vam-fz0 dib-vam-fz0-item" :style="{height:item.tips ? '340px' : '220px',marginBottom:item.tips ? '50px' : ''}" v-for="(item,index) in resultList"  :key="index">
              <!-- <div  class="check_ele">
              <el-checkbox v-model="item.checked"></el-checkbox>
            </div> -->

              <div class="left">
                <img v-if="item.imageUrl" :style="{maxHeight:getliu()}" @load="loadImage($event)" :src="queryDataUrl(item.imageUrl)" alt="" @click="goDetail(item)">
                <span style="font-size: 35px;font-weight: bold" v-else>{{item.sbmc}}</span>
              </div>
              <div class="right" @click="goDetail(item)">
                <div style="display: flex;justify-content: space-between">
                  <div class="trademark-name"><span style="font-size: 18px;font-weight: 500;color: #1A3DBF;display: inline-block;max-width: 250px;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;">{{item.sbmc}}</span> <span>{{$t(`PQ.accordion[12]`)}}<span style="font-size: 14px;display: inline-block;max-width: 150px;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;">{{item.intcls}}</span>{{$t(`PQ.accordion[13]`)}} <i v-if="item.miaoSu">-{{item.miaoSu}}</i></span> <span :style="{background:setColor(item.tmstatus), color: '#FDFDFE',fontWeight: '400'}">{{item.alias}}</span></div>
                  <div v-if="!isapplication" class="height_level" style="height: 30px;display: flex;align-items: center;">
                    <div style="width: 165px;display: flex" class="small-item">
                      <!-- <span class="title" v-if="item.level">近似度</span> -->
                      <div class="text"
                           :class="{'level-high':item.level=='高' || item.level=='High','level-middle':item.level=='中' || item.level=='Middle','level-low':item.level=='低' || item.level=='Low'}">
                        <!--                        <span style="display:inline-block;font-size: 14px;font-weight: 400;color: #333333;margin-right: 20px">{{$t(`PQ.accordion[13]`)}}</span>-->
                        <i class="level-graphical"></i>
                        <i class="text-level">{{item.level}}</i>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <p style="font-size:16px;margin-bottom:10px;">
                商标名称:{{item.sbmc}}
                <span class="text">中文：{{item.tmcn}}</span><span style="margin-left:10px;">英文：{{item.tmen}}</span>
              </p> -->
                <div class="details-container dib-vam-fz0">
                  <div class="details-item a">
                    <!--                    <p class="small-item">-->
                    <!--                      <span class="title">商标名称</span>-->
                    <!--                      <span class="text">{{item.sbmc}}</span>-->
                    <!--                    </p>-->
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.details_item[0]`)}}</span>
                      <span class="text" style="color: #eb5657;">{{item.tmstatus}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.accordion[4]`)}}</span>
                      <span class="text">{{item.appdate}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.accordion[5]`)}}</span>
                      <span class="text">{{item.regdate}}</span>
                    </p>
                    <p class="small-item">
                      <span  class="title">{{$t(`PQ.details_item[3]`)}}</span>
                      <span class="text">{{item.showAdress}}</span>
                    </p>
                  </div>
                  <div class="details-item b">
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.details_item[4]`)}}</span>
                      <span class="text">{{item.tmcn}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.details_item[5]`)}}</span>
                      <span class="text">{{item.regno}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.details_item[6]`)}}</span>
                      <span class="text">{{item.showName}}</span>
                    </p>
                  </div>
                  <div class="details-item c">
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.details_item[7]`)}}</span>
                      <span class="text">{{item.tmen}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.accordion[3]`)}}</span>
                      <span class="text">{{item.intcls}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title">{{$t(`PQ.details_item[9]`)}}</span>
                      <span class="text">{{item.showTmdetail}}</span>
                    </p>
                    <p class="small-item">
                      <span class="title"></span>
                      <span class="text"></span>
                    </p>
                  </div>
                  <!--                  <div class="details-item d">-->
                  <!--                    <p class="small-item">-->
                  <!--                      &lt;!&ndash; <span class="title" v-if="item.level">近似度</span> &ndash;&gt;-->
                  <!--                      <span class="text"-->
                  <!--                            :class="{'level-high':item.level=='高','level-middle':item.level=='中','level-low':item.level=='低'}">-->
                  <!--                      <i class="level-graphical"></i>-->
                  <!--                      <i class="text-level">{{item.level}}</i>-->
                  <!--                    </span>-->
                  <!--                    </p>-->
                  <!--                    <p class="small-item">-->
                  <!--                      <span class="title"></span>-->
                  <!--                      <span class="text"></span>-->
                  <!--                    </p>-->
                  <!--                    <p class="small-item">-->
                  <!--                      <span class="title"></span>-->
                  <!--                      <span class="text"></span>-->
                  <!--                    </p>-->
                  <!--                  </div>-->

                </div>
                <div style="margin-left: -246px;font-size: 20px;color: #666666;margin-top: 48px;text-align: center;" v-if="item.tips">
                  <p>{{item.hint}}</p>
                </div>
              </div>

            </li>

          </ul>
          <!--图标版-->
          <ul :class="['result-ico']"  v-if="switchStyle">


            <el-popover popper-class="query-result-ico-popover" v-for="item in resultList" v-if=" resultList && resultList.length"
                        :key="'icos'+item.id" placement="bottom" width="400" trigger="hover">
              <div  :class="['container',{'pq_active': !isCN}]">
                <section class="top">
                  <div class="left">
                    <div class="tuyang">
                      <img  @load="loadImage($event)" :src="queryDataUrl(item.imageUrl)" alt="">
                    </div>
                    <div class="degree" v-if="item.level">
                      <span class="degree-title title">{{$t(`PQ.accordion[13]`)}}</span>
                      <div class="degree-text text">
                        <div v-if="item.level=='高' || item.level=='High'" class="jinsidu">
                          <p class="text">{{item.level}}</p>
                          <p class="rate"><span class="high-span"></span></p>
                        </div>
                        <div v-if="item.level=='中' || item.level=='Middle'" class="jinsidu">
                          <p class="text">{{item.level}}</p>
                          <p class="rate"><span class="middle-span"></span></p>
                        </div>
                        <div v-show="item.level=='低' || item.level=='Low'" class="jinsidu">
                          <p class="text">{{item.level}}</p>
                          <p class="rate"><span class="low-span"></span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="right">
                    <li>
                      <p class="title">{{$t(`PQ.details_item[6]`)}}:</p>
                      <p class="text">
                        <span>{{item.showName}}</span>
                      </p>
                    </li>
                    <li>
                      <p class="title">{{$t(`PQ.details_item[3]`)}}:</p>
                      <p class="text">
                        <span>{{item.showAdress}}</span>
                      </p>
                    </li>
                    <li>
                      <p class="title">{{$t(`PQ.details_item[6]`)}}:</p>
                      <p class="text">{{item.regno}}</p>
                    </li>
                    <li>
                      <p class="title">{{$t(`PQ.accordion[3]`)}}:</p>
                      <p class="text">{{item.intcls}}</p>
                    </li>
                    <li>
                      <p class="title">{{$t(`PQ.details_item[0]`)}}:</p>
                      <p class="text">{{item.tmstatus}}</p>
                    </li>
                    <li>
                      <p class="title">{{$t(`PQ.accordion[4]`)}}: </p>
                      <p class="text">{{item.appdate}}</p>
                    </li>
                    <li>
                      <p class="title">{{$t(`PQ.accordion[5]`)}}:</p>
                      <p class="text">{{item.regdate}}</p>
                    </li>
                  </ul>
                </section>
                <section class="line" v-if="false&&!item.isDisabled"></section>
                <section class="bottom" v-if="false&&!item.isDisabled">
                  <span class="btn" @click="confirmFn2(item)">去续展</span>
                </section>
              </div>
              <li slot="reference" @click="goDetail(item)">
                <p class="see-big-img" @click="aLargerPreview(item)" v-if="false">
                  <span>大图预览</span>
                  <!--大图预览-->
                  <i class="el-icon-zoom-in"></i>
                </p>
                <div class="trademark-pattern">
                  <div class="trademark-pattern-img">
                    <p class="small-item" v-if="!isapplication">
                      <!-- <span class="title" v-if="item.level">近似度</span> -->
                      <span class="text"
                            :class="{'level-high':item.level=='高' || item.level=='High','level-middle':item.level=='中' || item.level=='Middle','level-low':item.level=='低' || item.level=='Low'}">
                          <i class="level-graphical"></i>
                          <i class="text-level">{{item.level}}</i>
                        </span>
                    </p>
                    <span class='state' :style="{background:setColor(item.tmstatus), color: '#FFFFFF'}">{{item.alias}}</span>
                    <img :style="{height:getqi()}"  @load="loadImage($event)" :src="queryDataUrl(item.imageUrl)" alt="" v-if="item.imageUrl">
                    <!--                      <img :style="{height:getqi()}"  @load="loadImage($event)" :src="item.imageBHL" alt="" v-if="item.imageBHL">-->
                    <span style="font-size: 35px;font-weight: bold" v-else>{{item.sbmc}}</span>
                  </div>
                </div>
                <dl style="color:#000;font-size: 14px">
                  <dd><span style="display: inline-block;">{{$t(`PQ.accordion[12]`)}}</span> <span style="display: inline-block;max-width: 150px;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;vertical-align: text-top;">{{item.intcls}}</span> <span style="display: inline-block;">{{$t(`PQ.accordion[13]`)}}</span><i v-if="item.miaoSu">-{{item.miaoSu}}</i></dd>
                  <dd>{{$t(`PQ.details_item[6]`)}} {{item.regno}}</dd>
                  <dd>{{$t(`PQ.details_item[7]`)}} {{item.showName}}</dd>
                </dl>
                <!--                  <p class="trademark-reg" @click>{{item.regno}}</p>-->
                <img :src="queryDataUrl(item.imageUrl)" alt="" class="showbig" v-if="item.showBig">
              </li>
              <div slot="reference" style="text-align:center;font-size: 20px;color: #666666;margin-bottom: 30px" v-if="item.tips">
                <p>{{item.hint}}</p>
              </div>
            </el-popover>

          </ul>
        </div>
      </section>

      <section v-else class="no-result" v-show="queryModes&&!resultList.length">
        <i>
          <img src="@/assets/images/common/nodata.png" class="" />
        </i>
        <p>{{$t(`PQ.regno[2]`)}}</p>
      </section>
      <section class="blank" v-if="queryModes"></section>
      <section v-if="!queryModes" :class="queryConditon || boxShow ? 'sub_disclaimers' : 'disclaimers'"></section>
      <section style="text-align: center" v-if="!queryModes" :class="queryConditon || boxShow ? 'sub_disclaimers' : 'disclaimers'">

        <p style="white-space: nowrap">{{$t('trademarkSearch.statementPre')}}<span @click="supportcountry" style="cursor: pointer;color: #4c92d8;"> {{$t('trademarkSearch.tableName')}} </span>{{$t('trademarkSearch.table')}}</p>
        <p style="white-space: nowrap">{{$t('trademarkSearch.statementafter')}}</p>



      </section>
      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
                 :before-close="closeEvent">
        <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
      </el-dialog>
    </div>

  </div>

</template>

<script>
  const statusObj = {
    '1': '已注册',
    '2': '无效',
    '3': '公告中',
    '4': '驳回复审审查中',
    '5': '异议审查中',
    '6': '注册申请审查中',
    '7': '撤销/无效宣告申请审查中',
    '8': '其他情形',
    '9': '无效',
    '10': '已注册',
    '11': '公告中',
    '12': '审查中'
  }

  const subStatusObj = {
    '已注册': '已注册',
    '无效': '无 效',
    '公告中': '公告中',
    '驳回复审审查中': '审查中',
    '异议审查中': '审查中',
    '注册申请审查中': '审查中',
    '撤销/无效宣告申请审查中': '审查中',
    '其他情形': '其 他',
    '审查中': '审查中'
  }

  const statueColorObj = {
    '已注册': '#B3D465',
    '无效': '#EB6100',
    '公告中': '#5779EE',
    '驳回复审审查中': '#FFBD54',
    '异议审查中': '#FFBD54',
    '注册申请审查中': '#FFBD54',
    '撤销/无效宣告申请审查中': '#FFBD54',
    '其他情形': '#7B578C',
    '审查中': "#FFBD54"
  }

  const type = {
    "01": "化工",
    "02": "颜料",
    "03": "日化",
    "04": "燃料",
    "05": "医药",
    "06":  "金属",
    "07": "机械",
    "08": "器具",
    "09": "电子",
    "10": "医用",
    "11": "灯具",
    "12": "车辆",
    "13": "火药",
    "14": "珠宝",
    "15": "乐器",
    "16": "文具",
    "17": "橡胶",
    "18": "皮具",
    "19": "建材",
    "20": "家具",
    "21": "厨具",
    "22": "绳网",
    "23": "纱线",
    "24": "布料",
    "25": "服装",
    "26": "纽扣",
    "27": "地毯",
    "28": "玩具",
    "29": "肉油",
    "30": "米面",
    "31": "果蔬",
    "32": "啤酒",
    "33": "白酒",
    "34": "烟草",
    "35": "广告",
    "36": "金融",
    "37": "建筑",
    "38": "通讯",
    "39": "运输",
    "40": "加工",
    "41": "文娱",
    "42": "科技",
    "43": "酒店",
    "44": "医疗",
    "45": "法律"
  }
  //获取所有的国家
  import allCountry from './allCountry'
  import moment from "moment";
  import { Message } from 'element-ui';
  import { mapGetters, mapMutations, mapState } from "vuex";
  import {
    createFn,
    queryTmInfoTextByMulCountryFromDB,
    queryTmInfoGraphByMulCountryFromDB,
    progressBarFn
  } from "./querys";
  import {classifyData} from './data';//尼斯分类
  import classify from "./public/classInTheNiceClassification";
  import viennaCodezu from "./public/viennaCodezu";
  import imgDefault from "../../assets/images/query/upload.png";
  import progressBar from "./public/progressBar";
  import progressp from "./public/progress";

  const queryParamsMap = {
    'country': 'countryData'
  }
  import login from "@/components/common/login"
  // 亚洲
  const cityOptions = [
    {
      city:'中国大陆',
      code:'CN',
      ingSrc:'/images/national/zgdl.jpg'
    },
    {
      city:'中国香港',
      code:'HK',
      ingSrc:'/images/national/zgxg.jpg'
    },
    {
      city:'印度尼西亚',
      code:'ID',
      ingSrc:'/images/national/ydnxy.jpg'
    },
    {
      city:'印度',
      code:'IN',
      ingSrc:'/images/national/yd.jpg'
    },
    {
      city:'日本',
      code:'JP',
      ingSrc:'/images/national/rb.jpg'
    },
    {
      city:'土耳其',
      code:'TR',
      ingSrc:'/images/national/trq.jpg'
    },
    {
      city:'韩国',
      code:'KR',
      ingSrc:'/images/national/hg.jpg'
    },
    {
      city:'中国澳门',
      code:'MO',
      ingSrc:'/images/national/am.jpg'
    },
    {
      city:'马来西亚',
      code:'MY',
      ingSrc:'/images/national/mlxy.jpg'
    },
    {
      city:'菲律宾',
      code:'PH',
      ingSrc:'/images/national/flb.jpg'
    },
    {
      city:'新加坡',
      code:'SG',
      ingSrc:'/images/national/xjp.jpg'
    },
    {
      city:'中国台湾',
      code:'TW',
      ingSrc:'/images/national/zgtw.png'
    },
    {
      city:'泰国',
      code:'TH',
      ingSrc:'/images/national/tgs.jpg'
    },
    {
      city:'阿拉伯联合酋长国',
      code:'AE',
      ingSrc:'/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city:'奥地利',
      code:'AT',
      ingSrc:'/images/national/adl.JPG'
    },
    {
      city:'瑞士',
      code:'CH',
      ingSrc:'/images/national/rs.jpg'
    },
    {
      city:'德国',
      code:'DE',
      ingSrc:'/images/national/dg.jpg'
    },
    {
      city:'丹麦',
      code:'DK',
      ingSrc:'/images/national/dm.jpg'
    },
    {
      city:'挪威',
      code: 'NO',
      ingSrc:'/images/national/nw.jpg'
    },
    {
      city:'西班牙',
      code:'ES',
      ingSrc:'/images/national/xby.jpg'
    },
    {
      city:'欧盟',
      code:'EU',
      ingSrc:'/images/national/om.jpg'
    },
    {
      city:'芬兰',
      code:'FI',
      ingSrc:'/images/national/fl.jpg'
    },
    {
      city:'法国',
      code:'FR',
      ingSrc:'/images/national/fg.jpg'
    },
    {
      city:'英国',
      code:'GB',
      ingSrc:'/images/national/yg.jpg'
    },
    {
      city:'葡萄牙',
      code:'PT',
      ingSrc:'/images/national/pty.jpg'
    },
    {
      city:'希腊',
      code:'GR',
      ingSrc:'/images/national/xl.jpg'
    },
    {
      city:'匈牙利',
      code:'HU',
      ingSrc:'/images/national/xyl.jpg'
    },
    {
      city:'爱尔兰',
      code:'IE',
      ingSrc:'/images/national/ael.jpg'
    },
    {
      city:'冰岛',
      code:'IS',
      ingSrc:'/images/national/bd.jpg'
    },
    {
      city:'意大利',
      code:'IT',
      ingSrc:'/images/national/ydl.jpg'
    },
    {
      city:'瑞典',
      code:'SE',
      ingSrc:'/images/national/rd.jpg'
    },
    {
      city:'俄罗斯',
      code:'RU',
      ingSrc:'/images/national/elss.jpg'
    },
    {
      city:'比荷卢经济联盟',
      code:'BX',
      ingSrc:'/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city:'加拿大',
      code:'CA',
      ingSrc:'/images/national/jnd.jpg'
    },
    {
      city:'墨西哥',
      code:'MX',
      ingSrc:'/images/national/mxg.jpg'
    },
    {
      city:'美国',
      code:'US',
      ingSrc:'/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city:'阿根廷',
      code:'AR',
      ingSrc:'/images/national/agt.jpg'
    },
    {
      city:'巴西',
      code:'BR',
      ingSrc:'/images/national/bx.jpg'
    },
    {
      city:'智利',
      code:'CL',
      ingSrc:'/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city:'南非',
      code:'ZA',
      ingSrc:'/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city:'澳大利亚',
      code:'AU',
      ingSrc:'/images/national/adly.jpg'
    },
    {
      city:'新西兰',
      code:'NZ',
      ingSrc:'/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city:'世界知识产权组织国际局',
      code:'WO',
      ingSrc:'/images/national/zscq.jpg'
    }
  ]

  export default {
    name: "query",
    metaInfo: {
      title: '商标查询', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索,图片识别,商标数据,近似商标,中国商标,商标名,商标分类,商标图片,商标文字,商标图片查询api'
      }, {                 // set meta
        name: 'description',
        content: '基于全球商标图片，按照维也纳编码分类，对图片进行特征提取和机器学习，通过"以图搜图"AI智能方式查询，准确查找出近似图片。同时，支持商标文字查询,商标档案查看等'
      }],
    },
    data () {
      return {
        isCN: true,
        dataType: this.$t(`PQ.dataType`),
        //申请号下不显示申请号近似
        isapplication: true,
        radio: 3,
        //是否选择类别
        isCategory: false,
        //part
        partResult:[],
        //获取全部结果
        allResult: [],
        //国家的选择
        choiceCountry: [],
        //监听页面滚动条
        isScroll: false,
        //已筛选国家
        selectedCountries: [],
        queryDirection: true,
        value: 2,
        //是否是条件筛选
        flag: 0,
        //组合查询的查询条件
        queryCriteria:{},
        //结果需要筛选的条件
        responseData: {
          applicant_list: []
        },
        //组合查询
        combinedquery:false,
        queryItemDefault: {
          tradeMarkText: {
            defaultText: "商标名称",
            keys: "SBMC",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          }, //
          tradeMarkPattern: {
            defaultText: "商标图形",
            keys: "SBTP",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          }, //value存储file
          patternElement: {
            defaultText: "图形要素编码",
            keys: "TXYSBM",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          classify: {
            defaultText: "分类",
            keys: "LB",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          applicationNumber: {
            defaultText: "申请号",
            keys: "SQH",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          registerNumber: {
            defaultText: "注册号",
            keys: "ZCH",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          tmApplicant: {
            defaultText: "申请人名称",
            keys: "SQRMC",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          tmAddress: {
            defaultText: "申请人地址",
            keys: "SQRDZ",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 1
          },
          status: {
            defaultText: "商标状态",
            keys: "SBZT",
            cnText: "",
            enText: "ewrwer",
            value: [],
            isMust: 2
          },
          istotal: {
            defaultText: "是否共有商标",
            keys: "SFGYSB",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          active: {
            defaultText: "是否有效",
            keys: "SFYX",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          },
          trialDate: {
            defaultText: "初审公告日",
            keys: "CSGGR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          appDate: {
            defaultText: "申请日",
            keys: "SQR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          regDate: {
            defaultText: "注册日",
            keys: "ZCR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          ggDate: {
            defaultText: "注册公告日",
            keys: "ZCGGR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          priorityDate: {
            defaultText: "优先权日",
            keys: "YXQR",
            cnText: "",
            enText: "ewrwer",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          privateDate: {
            defaultText: "专用权到期日",
            keys: "ZYQDQR",
            cnText: "",
            enText: "ewrwer",
            value: "",
            beginValue: "",
            endValue: "",
            isMust: 2,
            valueStr: ""
          },
          tmAgent: {
            defaultText: "代理机构",
            keys: "DLJG",
            cnText: "",
            enText: "ewrwer",
            value: "",
            isMust: 2
          }
        },
        queryItem: {},
        queryMode: {
          //查询模式,status,0精确查询,1近似查询,2包含查询
          // list: [
          //   {defaultText: "精确查询", cnText: "精确查询", enText: "exactQuery", val: "term"},
          //   {defaultText: "近似查询", cnText: "近似查询", enText: "approximateQuery", val: "ai"},
          //   {defaultText: "包含查询", cnText: "包含查询", enText: "includeQuery", val: "match"},
          // ],
          choosed: this.$store.state.query.queryData.queryWay
        },
        defaultText: {
          uploadText: "拖拽图片或点击上传",
          patternText: "与商标字段互斥，有商标内容则不显示图形要素",
          classifyText:
            "请输入1-45之间的阿拉伯数字,类别之间用;分隔，例如查询第九类输入：9;",
          plaseChoose: "请选择"
        },
        cnText: {
          uploadText: "拖拽图片或点击上传",
          patternText: "与商标字段互斥，有商标内容则不显示图形要素",
          classifyText: "请输入1-45之间的阿拉伯数字，例如查询第九类输入：9",
          plaseChoose: "请选择"
        },
        enText: {
          uploadText: "tuotuotuto",
          patternText: "tuotuotuto",
          classifyText: "aaaa",
          plaseChoose: "please choose"
        },
        /*维也纳分类,即图形像素*/
        ViennaVisible: false, //维也纳分类Vienna
        /*尼斯分类*/
        niceVisible: false,
        niceChoosedArr: [], //选中的尼斯分类数组
        niceAll: [], //接口获取到整个一二级的尼斯分类,
        //niceChoosedStr:"", // 选中的图形像素,见computed
        /*0124分类内容*/
        // classifyData: JSON.parse(JSON.stringify(classifyData)),//分类内容
        imgUplad: "", //上传的图片
        imgDefault: imgDefault, //默认的图片,即上传图片
        // 高级查询改版之后---初始化数据start
        val: "",
        isAddInput: false,
        query_Item1: [
          //固定查询下拉列表
          {
            tmParams: "",
            input: "",
            options: [
              //   {
              //   value: "SBMC",
              //   label: "商标名称",
              //   disabled: false
              // },
              //   {
              //     value: "SBTP",
              //     label: "商标图片",
              //     disabled: false
              //   },
              {
                value: "SQRMC",
                label: "申请人名称",
                disabled: false
              },
              {
                value: "SQH",
                label: "申请号",
                disabled: false
              }

            ]
          }
        ],
        query_Items: this.$t(`PQ.query_Items`),
        // 高级查询改版之后---初始化数据end
        flagdata: "",
        selectList: [],
        onlyVipCanQuery: false,
        isSelectAll: false,
        //全部选择
        allChecked: false,
        //是否显示类别
        queryConditon: true,
        asideForms: {
          applicant: [],
          niceList: [],
          appYear: [],
          regYear: [],
          active: [],
          tmstatuscode: [],
          agent:[]
        },
        //是否显示可注册性报告
        isReport:'',
        arr: [],
        newData: [],
        //全类数据
        allType:[],
        //出现结果的搜索条件
        condition:'',
        //没有类别数据提示
        noData:false,
        //----------------
        fileList:[],
        //数据库
        boxShow:false,
        //是否旋转
        isRotate:true,
        //数据库全选
        checked: false,
        //亚洲
        asiaCheckAll: false,
        asiaIsIndeterminate: true,
        // 欧洲
        europeCheckAll: false,
        europeIsIndeterminate: false,
        // 北美洲
        americaCheckAll: false,
        americaIsIndeterminate: false,
        // 南美洲
        southCheckAll: false,
        southIsIndeterminate: false,
        // 非洲
        africaCheckAll: false,
        africaIsIndeterminate: false,
        // 大洋洲
        oceaniaCheckAll: false,
        oceaniaIsIndeterminate: false,
        //世界知识产权组织
        propertyCheckAll: false,
        propertyIndeterminate: false,
        //亚洲
        asia: ['CN'],
        asiaCity: this.$t(`PQ.cityOptions`),
        // 欧洲
        europe: [],
        europeCity: this.$t(`PQ.city`),
        // 北美洲
        america: [],
        americaCity: this.$t(`PQ.cityOptionsd`),
        // 南美洲
        south: [],
        southCity: this.$t(`PQ.cityOptionsds`),
        // 非洲
        africa: [],
        africaCity: this.$t(`PQ.cityOpts`),
        // 大洋洲
        oceania: [],
        OceaniaCity: this.$t(`PQ.cityds`),
        //知识产权组织
        property: [],
        propertyOrganizationCity: this.$t(`PQ.propertyOrganization`),
        dialogFormVisible:true,
        dialogClose: false,
        /*动画相关*/
        searchBoxAppear: true,
        inputFocus: false,
        btnHover: false,
        // riverHhorseAppear: false,
        /*查询方式*/
        queryModes: "",//image,text
        /*查询条件*/
        firstList: this.$t(`PQ.firstList`),//param,queryWay爲後臺需要,classControl爲類名控制需要;
        firstChoosed: "",
        /*筛选条件*/
        niceList: [],//尼斯分类
        tmStatusList: [
          { name: "商标已注册", isCheck: false, code: 1 },
          { name: "商标申请中", isCheck: false, code: 2 },
          { name: "初审公告中", isCheck: false, code: 3 },
          { name: "驳回复审中", isCheck: false, code: 4 },
          { name: "商标异议中", isCheck: false, code: 5 },
          { name: "商标无效", isCheck: false, code: 6 },
          { name: "其他情形", isCheck: false, code: 7 }
        ],//法律状态
        appDateList: [],//申请日期
        moreObj: {
          category: false,
          status: false,
          year: false,
        },
        searchText: "",
        pageNo: 1,//当前页数
        //滚动条底部页数
        pageNos:1,
        // pageSize: 500,//每页查询多少条
        total: 0,
        pagesTotal: 500,
        base64Url: "",//需要查询的文件
        imageCodeList: "",//图形编码
        param: "",//默认为空，  1为商标名称查询/2为申请人查询/3为申请号查询/4为代理公司查询
        selectedArea: "",//selectedArea
        /*结果*/
        // resultList: [2],
        resultList: [],
        switchStyle: false,
        // 用户选择的默认显示形式  表格或图文
        switchStyleDefault: false,
        dialogVisible: false,
        serviceNameType: "",
        resultBool: false,
        countryList: [{
          value: 'CN',
          label: '中国大陆'
        }, {
          value: 'EU',
          label: '欧盟'
        }, {
          value: 'US',
          label: '美国'
        }],
        countryVal: 'CN',
        tag: ''
      }
    },
    components: {
      login,
      classify,
      viennaCodezu,
      progressp
      //   headers
    },
    created () {
      //创建类别 0-45
      for (let i = 1; i <= 45; i++) {
        this.niceList.push({
          name: i < 10 ? "0" + i : i, categoryNo: i < 10 ? "0" + i : i, isCheck: false, typeName: this.dataType[i-1].name
        })
      }

      this.queryItem = JSON.parse(JSON.stringify(this.queryItemDefault));
      // if (_highQueryConditions) {
      //   for (let k in this.queryItem) {
      //     if(this.queryItem[k]['keys'] === _highQueryConditions[0].key) {
      //       this.query_Item1[0].tmParams = _highQueryConditions[0].key
      //       this.queryItem[k].value = _highQueryConditions[0].value
      //       this.imgUplad = _highQueryConditions[0].imgUplad
      //     }
      //   }
      //
      //   if (_highQueryConditions[1].length < 1) {
      //     return false
      //   } else if (_highQueryConditions[1].length == 1) {
      //     for (let s in this.queryItem) {
      //       if(this.queryItem[s]['keys'] === _highQueryConditions[1][0].key) {
      //         this.query_Items[0].tmParams = _highQueryConditions[1][0].key
      //         this.queryItem[s].value = _highQueryConditions[1][0].value
      //         this.queryItem[s].beginValue = _highQueryConditions[1][0].beginValue
      //         this.queryItem[s].endValue = _highQueryConditions[1][0].endValue
      //         this.imgUplad = _highQueryConditions[1][0].imgUplad
      //       }
      //     }
      //   } else if (_highQueryConditions[1].length > 1) {
      //     for (var i = 0; i < _highQueryConditions[1].length; i ++) {
      //       this.addInput()
      //       for (let n in this.queryItem) {
      //         if(this.queryItem[n]['keys'] === _highQueryConditions[1][i].key) {
      //           this.query_Items[i].tmParams = _highQueryConditions[1][i].key
      //           this.queryItem[n].value = _highQueryConditions[1][i].value
      //           this.queryItem[n].beginValue = _highQueryConditions[1][i].beginValue
      //           this.queryItem[n].endValue = _highQueryConditions[1][i].endValue
      //           this.imgUplad = _highQueryConditions[1][i].imgUplad
      //         }
      //       }
      //     }
      //     let val = '', index = _highQueryConditions[1].length;
      //     this.query_Items.splice(index, 1);
      //     var query_Items = this.query_Items;
      //     var query_Item1 = this.query_Item1;
      //     query_Items.forEach(function(items, index) {
      //       let options = items.options;
      //       options.forEach(function(item, i) {
      //         if (item.value == val && val != "") {
      //           item["disabled"] = false;
      //         }
      //       });
      //     });
      //     query_Item1.forEach(function(items, index) {
      //       let options = items.options;
      //       options.forEach(function(item, i) {
      //         if (item.value == val && val != "") {
      //           item["disabled"] = false;
      //         }
      //       });
      //     });
      //     //删除输入框，要清空存值
      //     for (var key in this.queryItem){
      //       if (this.queryItem[key].keys == val && val != "") {
      //         this.queryItem[key].value="";
      //         this.queryItem[key].beginValue="";
      //         this.queryItem[key].endValue="";
      //       }
      //     }
      //     //商标图片删除，
      //     if(val=='SBTP'){
      //       this.imgUplad='';
      //     }
      //     //是否共有商标清空
      //     if(val=='SFGYSB'){
      //       this.queryItem.istotal.value="";
      //     }
      //     //是否有效清空
      //     if(val=='SFYX'){
      //       this.queryItem.active.value="";
      //     }
      //     if (query_Items.length <= 14) {
      //       this.isAddInput = false;
      //     }
      //   }
      // }
      // this.origin = window.location.origin;
      // this.origin = "dandan"; //正式环境,开发文字查询相关功能,则用此,否则请注释掉
      // this.queryItemsShowFn({
      //   fastOrHigh: 1
      // });
      // this.onlyVipCanQuery = true;
      // // this.$parent.getBoughtCountry(); // 查询结果页返回不需要再调查询国家接口，否则会清空国家筛选条件
    },
    mounted () {
      this.queryDataFn({
        countryList: allCountry.countryMaxDates,
        country: ['CN']
      })
      this.asideFormFn({
        country: [],
        applicant: [],
        applicantEn: [],
        niceList: [],
        imageFactory: [],
        appYear: [],
        regYear: [],
        trailYear: [],
        active: [],
        ggBeginYear: [],
        priorityYear: [],
        rightPeriod: [],
        tmstatuscode: [],
        commonBrand: []
      })

      //点击body不显示数据库
      $("body").click(() => {
        this.isRotate = true
        this.boxShow = false
      });

      $(window).scroll( () => {
        if($(window).scrollTop() >= 415){
          this.isScroll = true
        }else {
          this.isScroll = false
        }
      });
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
        this.$router.push('/PQ')
      }else {
        this.isCN = false
      }


    },
    destroyed () {
      $(window).unbind('scroll', this.scrollFn);
    },
    methods: {
      ...mapMutations("query", [
        "setClassifyShowFn",
        "queryItemsShowFn",
        "queryDataFn",
        "ajaxResponseFn",
        "othersItemFn",
        "pageNum_Fn",
        "pageSize_Fn",
        "noResetFn",
        "queryBtnClickNumFn",
        "responseDataFn",
        "sendQueryDataFn",
        "isFirstQueryFn",
        "asideFormFn",
        'setMe',
        'setNoticeList'
      ]),
      //login
      login(){
        this.$axios
          .get("/auth/oauth/token", {
            params: {
              checkCode: 'n',
              username: this.ruleForm.phone,
              password: this.ruleForm.password,
              code: '',
              token: this.token,
              grant_type: 'password',
              scope: 'server',
              authType: 'image'
            },
            headers: {
              Authorization: "Basic " + this.userSource.token
            }
          }).then((data) => {
          if (data.status == 200) {
            if (data.data.access_token) {
              this.setToken(data.data.access_token)
            }

            this.$emit("loginDialog", false);
            this.getUserInfo();
            this.pageAjax();
            this.$refs["ruleForm"].resetFields();
            this.codeMsg = this.$t("logins.form.sendCodeText");
            this.countdown = 60;
            clearInterval(this.timer);
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.wechatLogin = false;
            this.wechatAuth = false;
            this.unClick = false;
          }else {
            this.$message({
              message: err.response.data.msg
            });
          }
        })
          .catch(err => {
            clearInterval(this.timer);
            this.codeMsg = this.$t("logins.form.resendText");
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.$message({
              message: err.response.data.message
            })
          });
      },
      //返回
      goBack(){
        location.reload()
      },
      //浏览器判断
      getliu(){
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;
//以下进行测试
        if (Sys.safari) return '36%'
        else return '55%'
      },
      getqi(){
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;
//以下进行测试
        if (Sys.safari) return '35%'
        else return '50%'
      },
      //跳转支持国家页面
      supportcountry(){
        this.$router.push('/supportCountry')
      },
      //筛选国家
      countrySelect(code,val){

        if(val == 0) return;
        if(this.choiceCountry && this.choiceCountry.length == 0){
          this.choiceCountry = [code]
        }else if(this.choiceCountry.includes(code)){
          this.choiceCountry = []
        }else {
          this.choiceCountry = [code]
        }
      },
      //获取页面滚动条的位置
      scorll(){
        return document.documentElement.scrollTop || document.body.scrollTop;
      },
      //获取类别简写名称
      typeName(name){
        return type[name]
      },
      //获取图片的大小设置
      loadImage(dom) {
        // this.style.height = '10px!important' explicitOriginalTarget
        if ((dom.path && dom.path[0].clientWidth > 166) || dom.explicitOriginalTarget && dom.explicitOriginalTarget.clientWidth > 166) {
          if (dom.explicitOriginalTarget) {
            dom.explicitOriginalTarget.width = '100'
            // dom.explicitOriginalTarget.height = 'auto'
          } else if ((dom.path && dom.path[0].clientHeight > 166) || dom.explicitOriginalTarget && dom.explicitOriginalTarget.clientHeight > 166) {
            if (dom.explicitOriginalTarget) {
              dom.explicitOriginalTarget.height = '200'
              // dom.explicitOriginalTarget.width = 'auto'
            }
          } else {
            if (dom.explicitOriginalTarget) {
              dom.explicitOriginalTarget.width = '60%'
            } else {
              dom.target.style.width = '60%'
            }
          }
        }
      },
      //从新计算筛选栏高度
      calculation(){
        setTimeout(()=> {
          document.getElementsByClassName('left-secondQueryCondition')[0] ? document.getElementsByClassName('left-secondQueryCondition')[0].style.height = 0 : ''
          document.getElementsByClassName('left-secondQueryCondition')[0] ? document.getElementsByClassName('left-secondQueryCondition')[0].style.height =  document.getElementsByClassName('result')[0].offsetHeight + 'px' : ''
        },500)
      },
      //根据状态返回对应颜色
      setColor(color){
        return statueColorObj[color]
      },
      //删除选中国家
      delCountry(val){
        let codes = []
        if(this.choiceCountry.includes(val)) this.choiceCountry = []
        if (this.asia.indexOf(val) > -1) {
          this.asia.splice(this.asia.indexOf(val), 1);
        } else if (this.europe.indexOf(val) > -1) {
          this.europe.splice(this.europe.indexOf(val), 1);
        } else if (this.america.indexOf(val) > -1) {
          this.america.splice(this.america.indexOf(val), 1);
        } else if (this.south.indexOf(val) > -1) {
          this.south.splice(this.south.indexOf(val), 1);
        } else if (this.africa.indexOf(val) > -1) {
          this.africa.splice(this.africa.indexOf(val), 1);
        } else if (this.oceania.indexOf(val) > -1) {
          this.oceania.splice(this.oceania.indexOf(val), 1);
        } else if (this.property.indexOf(val) > -1) {
          this.property.splice(this.property.indexOf(val), 1);
        }
        codes = this.selectedCountries.map(item => item.name)
        if (codes.indexOf(val) > -1) {
          this.selectedCountries.splice(codes.indexOf(val), 1);
        }
      },
      changeInpText(v) {
        if (!v) return
        this.queryDataFn({
          tradeMarkText: v
        })
      },
      //条件筛选
      conditionalQuery(){
        this.flag =  1;
        // this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if(this.tool.getCookie("auth")){

        }else {
          this.dialogVisible = true
          return;
        }

        if(this.searchText){
          this.queryModes = "text";
        }

        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }

        this.searchFn();
      },
      //清除筛选框内条件
      clearCondition(key){ // 清空筛选框中的条件
        this.asideForms[key] = [];
        this.conditionalQuery()
      },
      goPurchase() {
        // if()
        this.$router.push({
          path: '/trademarkPayment'
        })
      },
      toSheet(){
        this.$router.push({
          path: '/query/searchableCountrySheet',
        })
      },
      goFish(){
        window.open("http://www.lanternfish.cn");
      },
      //动态输入框切换时触发
      swichFastHigh(index) { // 组合查询按钮
        this.queryDataFn({
          imgLocalUrl: this.queryData.imgDefault,
          imgLineUrl: "",
          classify: JSON.parse(JSON.stringify(this.queryData.classifyDefault))
        })
        this.queryItemsShowFn({
          reset: true,
        });
        this.queryItemsShowFn({
          fastOrHigh: index,
        });
        this.queryDataFn({
          reset: true,
        });
        this.othersItemFn({
          reset: true,
        });
        this.routerFn();
        this.queryDataFn({
          highQueryConditions: null
        })
        // this.$parent.getBoughtCountry()
      },
      routerFn() { // 切换快速查询和高级查询
        //0123上午有空放在utils中,目前main中,fastQuery中都有//0123下午需求变更,暂时不需要此函数
        if (this.queryItemsShow.fastOrHigh) {
          this.$router.push({
            path: "/query/high"
            // query: this.$route.query
          });
        } else {
          this.$router.push({
            path: "/query/fast"
            // query: this.$route.query
          });
        }
        this.queryItemsShowFn({
          result: false,
          condition: false,
          more: false
        });
        //清空查询条件
      },
      changeVal(val, index) {
        this.val = val;
        this.$set(
          this.query_Items,
          this.query_Items[this.query_Items.length - 1]["tmParams"],
          val
        );
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Item1.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Items.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        query_Item1.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });

        //切换输入框，要清空被切换的存值
        for (var key in this.queryItem){
          if(list.indexOf(this.queryItem[key].keys)==-1){
            if ("beginValue" in this.queryItem[key]) {
              this.queryItem[key].beginValue ='';
              this.queryItem[key].endValue ='';
            }else{
              this.queryItem[key].value="";
            }
          }
        }
        // if(val == 'SBZT') {

        // }
        //是否商标状态要默认否
        if(val=='SFGYSB'){
          this.queryItem.istotal.value="no";
        }
        //是否商有效要默认有效
        if(val=='SFYX'){
          this.queryItem.active.value="1";
        }
        //图片清空
        if(list.indexOf('SBTP')==-1){
          this.imgUplad="";
        }
      },
      //固定输入框切换时触发
      changeVal1(val) {
        this.val = val;
        if(this.val != 'SBMC') {
          this.queryMode.choosed = "term";
        }
        this.$set(
          this.query_Item1,
          this.query_Item1[this.query_Item1.length - 1]["tmParams"],
          val
        );
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Item1.forEach(function(item, index) {
          let options = item.options;
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
          options.forEach(function(item, i) {
            item["disabled"] = false;
          });
        });
        query_Items.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        query_Item1.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });

        //切换输入框，要清空被切换的存值
        for (var key in this.queryItem){
          if(list.indexOf(this.queryItem[key].keys)==-1){
            this.queryItem[key].value="";
          }
        }
        //图片清空
        if(list.indexOf('SBTP')==-1){
          this.imgUplad="";
          this.queryDataFn({
            imgLineUrl: ""
          })
        }
      },
      addInput() {
        this.query_Items.push({
          tmParams: "",
          input: "",
          options: [
            // {
            //   value: "SBMC",
            //   label: "商标名称",
            //   disabled: false,
            // },
            // {
            //   value: "SBTP",
            //   label: "商标图片",
            //   disabled: false
            // },
            {
              value: "SQRMC",
              label: "申请人名称",
              disabled: false
            },
            {
              value: "SQRDZ",
              label: "申请人地址",
              disabled: false
            },
            {
              value: "SQH",
              label: "申请号",
              disabled: false
            },
            // {
            //   value: "LB",
            //   label: "类别",
            //   disabled: false,
            // },
            {
              value: "SQR",
              label: "申请日",
              disabled: false
            },
            {
              value: "TXYSBM",
              label: "图形要素编码",
              disabled: false
            },
            {
              value: "CSGGR",
              label: "初审公告日",
              disabled: false
            },
            {
              value: "ZCR",
              label: "注册日",
              disabled: false
            },
            {
              value: "ZYQDQR",
              label: "专用权到期日",
              disabled: false
            },
            {
              value: "DLJG",
              label: "代理机构",
              disabled: false
            },
            {
              value: 'ZCH',
              label: '注册号',
              disabled: false
            },
            {
              value: "SBZT",
              label: "商标状态",
              disabled: false
            },
            {
              value: "SFGYSB",
              label: "是否共有商标",
              disabled: false
            },
            {
              value: "SFYX",
              label: "是否有效",
              disabled: false
            },
            //{
            // value: "ZCGGR",
            // label: "注册公告日",
            //  disabled: false
            //},
            {
              value: "YXQR",
              label: "优先权日",
              disabled: false
            }
          ]
        });
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        var list = [];
        query_Items.forEach(function(item, index) {
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
        });
        query_Item1.forEach(function(item, index) {
          if (item.tmParams != "") {
            list.push(item.tmParams);
          }
        });

        var query_Items2 = query_Items.slice(-1);
        query_Items2.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            list.forEach(function(ite) {
              if (ite == item.value) {
                item["disabled"] = true;
              }
            });
          });
        });
        // this.isDisabledSBZT();
        if (query_Items.length > 14) {
          this.isAddInput = true;
        }
      },
      delInput(val, index) {
        this.query_Items.splice(index, 1);
        var query_Items = this.query_Items;
        var query_Item1 = this.query_Item1;
        query_Items.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            if (item.value == val && val != "") {
              item["disabled"] = false;
            }
          });
        });
        query_Item1.forEach(function(items, index) {
          let options = items.options;
          options.forEach(function(item, i) {
            if (item.value == val && val != "") {
              item["disabled"] = false;
            }
          });
        });
        //删除输入框，要清空存值
        for (var key in this.queryItem){
          if (this.queryItem[key].keys == val && val != "") {
            this.queryItem[key].value="";
            this.queryItem[key].beginValue="";
            this.queryItem[key].endValue="";
          }
        }
        //商标图片删除，
        if(val=='SBTP'){
          this.imgUplad='';
        }
        //是否共有商标清空
        if(val=='SFGYSB'){
          this.queryItem.istotal.value="";
        }
        //是否有效清空
        if(val=='SFYX'){
          this.queryItem.active.value="";
        }
        if (query_Items.length <= 14) {
          this.isAddInput = false;
        }
        this.queryDataFn({
          classify: JSON.parse(JSON.stringify(classifyData))
        })
      },
      createFn(word) {
        createFn.bind(this)(word);
      },
      openVienna() {
        this.$nextTick(() => {
          this.$refs.viennaCodezuRef.updateVienna();
        });
      },
      //获取用户支持查询的国家
      supportquery(file) {
        this.$axios.get('/lantern/jurisdiction/getTrademarkServiceAuthority').then(({data}) => {
          if (data.code == 0) {
            //亚洲
            this.asiaCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 欧洲
            this.europeCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 北美洲
            this.americaCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 南美洲
            this.southCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 非洲
            this.africaCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            // 大洋洲
            this.OceaniaCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
            //知识产权组织
            this.propertyOrganizationCity.map(item => {
              if(data.data.includes(item.code)) item.disabled = false
              else item.disabled = true
            })
          }
        })
          .catch(error => {});
      },
      uploadFn(file) {
        this.resetProgress()
          .then(data => {
            if (data.code == 200) {
              this.flagdata = "";
              let bol = this.formatCheck({
                file: file,
                suffix: ["JPG", "PNG", "GIF", "TIFF"],
                // size: 2048000,
                size: 5048000 //5mb
              });
              if (!bol) {
                return;
              }
              this.uploadAnalysis({
                file: file.raw
              })
                .then(data => {
                  this.imgUplad = data.data;
                  this.flagdata = this.imgUplad;
                  this.queryMode.choosed = "similar";
                  this.queryDataFn({
                    queryWay: this.queryMode.choosed
                  });
                  this.queryDataFn({
                    imgFile: file,
                    imgLocalUrl: data.data,
                    imgLineUrl: data.data,
                    fastSearch: "",
                    tradeMarkText: "",
                    viennaFromAI: [],
                    viennaChoosedArr: []
                  });
                  // this.clearNotAiRequire();
                })
                .catch(error => {
                  console.log(error);
                });
              if (this.flagdata == "") {
                setTimeout(() => {
                  this.$refs.child[0].etUploadProgressFn();
                }, 300);
              }
              this.defaultText.uploadText = "已上传";
              this.cnText.uploadText = "已上传";
              this.enText.uploadText = "yishangchuan";
            }
          })
          .catch(error => {});
      },
      deleteImg() {
        this.imgUplad = "";
        this.queryDataFn({
          imgFile: null,
          imgLocalUrl: imgDefault,
          imgLineUrl: ""
        });
      },
      viennaInput() {
        /*末尾去;号*/
        let str = this.queryItem.patternElement.value;
        if (!str.length) {
          resetVienna.bind(this)();
          return;
        }
        str = str.substr(-1) == ";" ? str.substr(0, str.length - 1) : str;
        let arr = str.split(";");
        /*判断是否合法*/
        let bol = true;
        arr.map(item => {
          bol = this.noReset.viennaSecondText.includes(item);
        });
        if (!bol) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetVienna.bind(this)();
          return;
        }
        /*set*/
        this.queryDataFn({
          viennaChoosedArr: arr
        });

        //开启类别选择
        function resetVienna() {
          this.noReset.vienna.map(item => {
            item.isOpen = false;
            item.children.map(item2 => {
              item2.isOpen = false;
              item2.children &&
              item2.children.map(item3 => {
                item3.isOpen = false;
                // this.queryData.viennaChoosedArr = [];
                this.queryDataFn({
                  viennaChoosedArr: []
                });
              });
            });
          });
        }
      },
      classifyInput() {
        /*末尾补;号*/
        let str = this.queryItem.classify.value;
        if (!str.length) {
          resetClassify.bind(this)();
          return;
        }
        str = str.substr(-1) == ";" ? str : str + ";";
        let arr = str.match(/\d{1,2}/g);
        if (arr == null) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetClassify.bind(this)();
          return;
        }
        let strNew = arr.join(";") + ";";
        /*判断是否合法*/
        let bol = false;
        if (strNew == str) bol = true;
        let middleArr = arr.filter(item => item == 0 || item > 45 || item < 1);
        if (middleArr.length) bol = false;
        if (!bol) {
          this.$message(this.$t("follow_up_FE_lang.enter_error")); //输入错误
          resetClassify.bind(this)();
          return;
        }
        /*set*/
        arr.map((item, index) => {
          if (item < 10) arr[index] = "0" + item * 1;
        });
        arr = [...new Set(arr)];
        this.queryData.classify.map(item => {
          let ddd = arr.includes(item.no);
          if (ddd) {
            item.isCheck = true;
            item.isQuery = true;
          } else {
            item.isCheck = false;
            item.isQuery = false;
          }
        });
        this.queryDataFn();

        function resetClassify() {
          this.queryData.classify.map(item => {
            item.isCheck = false;
            item.isQuery = false;
          });
          this.queryDataFn();
        }
      },
      queryWayFn() {
        this.queryDataFn({
          queryWay: this.queryMode.choosed
        });
      },
      clearNotAiRequire() {
        this.queryItem.patternElement.value = "";
        // this.queryItem.tmApplicant.value = "";
        this.queryItem.tmAddress.value = "";
        this.queryItem.trialDate.beginValue = "";
        this.queryItem.trialDate.endValue = "";
        this.queryItem.appDate.beginValue = "";
        this.queryItem.appDate.endValue = "";
        this.queryItem.regDate.beginValue = "";
        this.queryItem.regDate.endValue = "";
        this.queryItem.privateDate.beginValue = "";
        this.queryItem.privateDate.endValue = "";
        this.queryItem.tmAgent.value = "";
        //this.queryItem.ggDate.beginValue = "";
        this.queryItem.priorityDate.endValue = "";
        this.queryItem.status.value = [];
      },
      isOpenFn(item) {
        item.isOpen = !item.isOpen;
      },
      viennaChange() {},
      // returnFn() {
      //   this.queryItemsShowFn({
      //     fastOrHigh: 0
      //   });
      //   this.$router.push({
      //     path: "/query/fast",
      //     query: this.$route.query
      //   });
      // },
      reset_Fn() {
        //     //删除输入框，要清空存值
        let val = ''
        for (var key in this.queryItem){
          if (this.queryItem[key].keys) {
            this.queryItem[key].value="";
            this.queryItem[key].beginValue="";
            this.queryItem[key].endValue="";
          }
        }
      },
      //商标状态是否可选
      isDisabledSBZT(){
        //判断是否已选
        let isSelected=false;
        this.query_Items.forEach(function(items, index) {
          if (items.tmParams=='SBZT') {
            isSelected = true;
          }
        });
        //中欧美以外其他国家商标状态未定，所以不可选
        if(this.queryData.country=="CN"||this.queryData.country=="EU"||this.queryData.country=="US"){
          this.query_Items.forEach(function(item, index) {
            let options = item.options;
            options.forEach(function(item2, i) {
              if(item2["value"]=='SBZT'){
                item2["disabled"] = isSelected;
              }
            });
          });
        }else{
          this.query_Items.forEach((item, index)=> {
            let options = item.options;
            //已选则删除
            if (item.tmParams=='SBZT') {
              this.delInput('SBZT',index);
            }
          });
          //上下两个循环合一起时 第二个修改不生效，原因未知
          this.query_Items.forEach((item, index)=> {
            let options = item.options;
            options.forEach((item2, i)=> {
              //未选不可选
              if(item2["value"]=='SBZT'){
                item2["disabled"] = true;
              }
            });
          });
        }
      },
      whichLandFn(v) {
        if (this.isSelectAll) {
          this.isSelectAll = false;
          this.queryDataFn({
            tabCountryName: '',
            country: this.queryData.countryList.filter(i => !i.disabled).map(i => i.countryKey).includes('CN') ? ['CN'] : []
          })
          this.asideFormFn({
            ...this.$store.state.query.asideForm,
            country: this.queryData.country,
          })
        } else if (v.includes('all') && !this.isSelectAll) {
          this.isSelectAll = true;
          this.queryDataFn({
            tabCountryName: '',
            country: [...this.queryData.countryList.filter(i => !i.disabled).map(i => i.countryKey)]
          })
          this.asideFormFn({
            ...this.$store.state.query.asideForm,
            country: [this.queryData.countryList[0].countryKey],
          })
        } else {
          this.queryDataFn({
            tabCountryName: ''
          })
        }
        //回复默认，不可图片查询
        this.queryData.isCanUploadImg=true;
        let tmpThis=this;
        this.queryData.countryList.map((countryItem) => {
          if(tmpThis.queryData.country.join(";").includes(countryItem.countryKey)){
            tmpThis.queryData.countryName=countryItem.chName;
            //部分国家暂不支持图片查询
            if(!countryItem.isGraphQuerySelect){
              tmpThis.queryData.isCanUploadImg=false;
            }
          }
        });
        //商标状态是否可选
        // this.isDisabledSBZT();
        let TM5CountyList = ['CN', 'EU', 'US', 'JP', 'KR'],
          OtherCountryList = ["AR", "AU", "AT", "BX", "BR", "CA", "CL", "DK", "FI", "FR", "DE", "GB", "GR", "HK", "HU", "IS", "IN", "ID", "IE", "IT", "MO", "MY", "MX", "NZ", "NO", "PH", "PT", "RU", "SG", "ZA", "ES", "SE", "CH", "TW", "TH", "TR", "AE", "WO"]
        let tm5Flag = this.queryData.country.every(i => TM5CountyList.includes(i)),
          otherFlag = this.queryData.country.every(i => OtherCountryList.includes(i));
        if(tm5Flag&&!otherFlag) {
          this.queryDataFn({
            countryStatusVuex: 0
          })
        } else if(!tm5Flag&&otherFlag) {
          this.queryDataFn({
            countryStatusVuex: 1
          })
        } else {
          this.queryDataFn({
            countryStatusVuex: 2
          })
        }
        this.queryItem.status.value = [];
      },
      highGradeQuery() {
        //选择的法律状态
        // if(this.queryData.statusList[this.queryData.country + '_list']){
        //   this.queryData.statusList[this.queryData.country+'_list'].map((item) =>{
        //     if(item.index==this.queryItem.status.value){
        //       item.isQuery=true;
        //     }else{
        //       item.isQuery=false;
        //     }
        //     item.isCheck=false;
        //   });
        // }

        /*isOk验证至少有一个必填项*/
        /*isOdd验证成对出现*/
        {
          let isOk = 0;
          for (let key in this.queryItem) {
            if ("beginValue" in this.queryItem[key]) {
              /*时间成对选择*/ //暂时不用成对
              if (
                this.queryItem[key].beginValue &&
                this.queryItem[key].endValue &&
                this.queryItem[key].beginValue > this.queryItem[key].endValue
              ) {
                this.$message(this.$t("reg_query_lang.right_range_of_time")); //请选择正确的时间段
                return;
              }
              let valueStr = "";
              if (this.queryItem[key].beginValue)
                valueStr +=
                  "从" +
                  moment(this.queryItem[key].beginValue).format("YYYY-MM-DD") +
                  "起 ";
              if (this.queryItem[key].endValue)
                valueStr +=
                  "到" +
                  moment(this.queryItem[key].endValue).format("YYYY-MM-DD") +
                  "止";
              this.queryItem[key].valueStr = valueStr;
            }
            if (this.queryItem[key].isMust == 1 && this.queryItem[key].value)
              isOk++;
          }

          // if (this.imgUplad) isOk++;
          /*如果至少有一个必选条件*/
          // if (isOk == 0) {
          //   // this.$message(this.$t('reg_query_lang.tips_which2'));//商标文字/商标图形/图样要素编码/申请号/注册号/申请人名称/申请人地址/代理机构,至少选一个
          //   this.$message("尊敬的用户，录入的条件有些少，请再填写一个吧！"); //需求1145
          //   return;
          // }
        }
        this.asideFormFn({
          ...this.$store.state.query.asideForm,
          country: [this.queryData.tabCountryName || this.queryData.country[0]],
          // country: [this.queryData.country[0]],
        })
        this.setClassifyShowFn();
        let sendData = {
          active:this.queryItem.active.value ? this.queryItem.active.value : undefined,
          tmName: this.queryItem.tradeMarkText.value ? this.queryItem.tradeMarkText.value : undefined,
          istotal: this.queryItem.istotal.value ? this.queryItem.istotal.value : undefined, //是否共有商标
          niceChoosedStr: this.niceChoosedStr ? this.niceChoosedStr : undefined, //选择的尼斯分类
          regno: this.queryItem.registerNumber.value ? this.queryItem.registerNumber.value.replace(/[.]+/g, '').replace(/[，,；;]+/g, ';') : undefined, //注册号
          address: this.queryItem.tmAddress.value ? this.queryItem.tmAddress.value : undefined, //申请人地址
          trialBeginDate: this.queryItem.trialDate.beginValue ? moment(this.queryItem.trialDate.beginValue).format("YYYY-MM-DD") : undefined,
          trialEndDate: this.queryItem.trialDate.endValue ? moment(this.queryItem.trialDate.endValue).format("YYYY-MM-DD") : undefined,
          priorityBeginDate: this.queryItem.priorityDate.beginValue ? moment(this.queryItem.priorityDate.beginValue).format("YYYY-MM-DD") : undefined,
          priorityEndDate: this.queryItem.priorityDate.endValue ? moment(this.queryItem.priorityDate.endValue).format("YYYY-MM-DD") : undefined,
          appBeginDate: this.queryItem.appDate.beginValue ? moment(this.queryItem.appDate.beginValue).format("YYYY-MM-DD") : undefined,
          appEndDate: this.queryItem.appDate.endValue ? moment(this.queryItem.appDate.endValue).format("YYYY-MM-DD") : undefined,
          regBeginDate: this.queryItem.regDate.beginValue ? moment(this.queryItem.regDate.beginValue).format("YYYY-MM-DD") : undefined,
          regEndDate: this.queryItem.regDate.endValue ? moment(this.queryItem.regDate.endValue).format("YYYY-MM-DD") : undefined,
          validBeginDate: this.queryItem.privateDate.beginValue ? moment(this.queryItem.privateDate.beginValue).format("YYYY-MM-DD") : undefined,
          validEndDate: this.queryItem.privateDate.endValue ? moment(this.queryItem.privateDate.endValue).format("YYYY-MM-DD") : undefined,
          tmstatuscode: this.queryItem.status.value.length > 0 ? this.queryItem.status.value.join(';') : undefined, //申请人地址
        };
        let optionalCondition = []
        this.query_Items.forEach(item => {
          let obj = {}
          for (let k in this.queryItem) {
            if (this.queryItem[k]["keys"] === item.tmParams) {
              obj.key = item.tmParams
              obj.beginValue = this.queryItem[k]['beginValue']
              obj.endValue = this.queryItem[k]['endValue']
              obj.value = this.queryItem[k]['value']
              obj.imgUplad = this.queryData.imgLineUrl
              optionalCondition.push(obj)
            }
          }
        })

        let solidCondition = {}
        for (let k in this.queryItem) {
          if (this.queryItem[k]["keys"] === this.query_Item1[0].tmParams) {
            solidCondition.key = this.query_Item1[0].tmParams
            solidCondition.value = this.queryItem[k]['value']
            solidCondition.imgUplad = this.queryData.imgLineUrl
          }
        }

        this.queryCriteria = sendData


      },
      niceConfirm() {
        this.niceVisible = false;
      },
      niceClose() {
        this.niceVisible = false;
      },
      /*0124尼斯分类*/
      classifyChange(item) {
        item.isCheck = !item.isCheck;
        item.isQuery = item.isCheck;
        if (
          this.queryData.classify.filter(item2 => item2.isCheck == false).length
        )
          this.othersItemFn({
            checkAll: false
          });
        else
          this.othersItemFn({
            checkAll: true
          });
        this.queryDataFn(); //刷新vuex工具,以便可视及时更新;
      },
      checkedAllFn() {
        this.queryData.classify.map(item => {
          item.isCheck = this.othersItem.checkAll;
          item.isQuery = this.othersItem.checkAll;
        });
        this.queryDataFn();
      },
      classifyCancel() {
        this.queryDataFn({
          classify: JSON.parse(JSON.stringify(this.queryData.classifyDefault))
        });
      },
      classifyConfirm() {
        //高级查询中确定不能让condition为true
        this.queryItemsShowFn({
          condition: false
        });
      },
      //类别选择
      category(){
        this.queryConditon = !this.queryConditon
      },
      //组合查询
      combinedQuery(){
        this.combinedquery = !this.combinedquery;
        return;
        this.$router.push({path:'/combinedQuery'})
      },
      blockClicks(){

      },
      clossOpen(){
        this.isRotate = true
        this.boxShow = false
      },
      //展示可注册分析报告
      jumpReport(){
        if(this.base64Url){
          this.graphSearch_FromDBs()
        }else if(this.searchText){
          this.querySearch_FromDBs();
        }
        // switch (this.queryModes) {
        //   case "image": {
        //     //   this.queryModes = "image";
        //       this.graphSearch_FromDBs()
        //     // this.getJurisdictionAjaxImages();
        //   }
        //     break;
        //   case "text": {
        //       this.querySearch_FromDBs();
        //     // this.getJurisdictionAjaxs();
        //   }
        //     break;
        // }
      },
      //商标支付
      apply(){
        if (this.tool.getCookie("auth")) {
          this.$router.push({path:'/trademarkPayment'})
        } else {
          this.dialogVisible = true
        }

      },
      //数据库
      database(value){
        if(value){
          this.selectAll()
        }else {
          this.cancelAll()
        }
      },
      //点击显示数据库
      rotate(){
        this.isRotate = !this.isRotate
        this.boxShow = !this.isRotate
      },

      //全部选中
      selectAll(){
        //数据库
        this.checked = true
        //亚洲
        this.asiaCheckAll = true
        this.handleCheckAllChange(true)
        // 欧洲
        this.europeCheckAll = true
        this.handleCheckAllChanges(true)
        // 北美洲
        this.americaCheckAll = true
        this.handleCheckAllChangess(true)
        // 南美洲
        this.southCheckAll = true
        this.handleCheckAllChangesss(true)
        // 非洲
        this.africaCheckAll = true
        this.handleCheckAllChangessss(true)
        // 大洋洲
        this.oceaniaCheckAll = true
        this.handleCheckAllChangesssss(true)
        //世界知识产权
        this.propertyCheckAll = true
        this.handleCheckAllChangessssse(true)
      },
      //全部取消
      cancelAll(){
        //数据库
        this.checked = false
        //亚洲
        this.asiaCheckAll = false
        this.handleCheckAllChange(false)
        // 欧洲
        this.europeCheckAll = false
        this.handleCheckAllChanges(false)
        // 北美洲
        this.americaCheckAll = false
        this.handleCheckAllChangess(false)
        // 南美洲
        this.southCheckAll = false
        this.handleCheckAllChangesss(false)
        // 非洲
        this.africaCheckAll = false
        this.handleCheckAllChangessss(false)
        // 大洋洲
        this.oceaniaCheckAll = false
        this.handleCheckAllChangesssss(false)
        // 世界知识产权
        this.propertyCheckAll = false
        this.handleCheckAllChangessssse(false)
      },
      //-------------------------------------------------选中------------------------------------------
      handleCheckAllChange(val) {
        this.asia = val ?  cityOptions.map(item => item.code) : []
        this.asiaIsIndeterminate = false;
      },
      handleCheckAllChanges(val) {
        this.europe = val ? city.map(item => item.code) : [];
        this.europeIsIndeterminate = false;
      },
      handleCheckAllChangess(val) {
        this.america = val ? cityOptionsd.map(item => item.code) : [];
        this.americaIsIndeterminate = false;
      },
      handleCheckAllChangesss(val) {
        this.south = val ? cityOptionsds.map(item => item.code) : [];
        this.southIsIndeterminate = false;
      },
      handleCheckAllChangessss(val) {
        this.africa = val ?  cityOpts.map(item => item.code) : [];
        this.africaIsIndeterminate = false;
      },
      handleCheckAllChangesssss(val) {
        this.oceania = val ? cityds.map(item => item.code) : [];
        this.oceaniaIsIndeterminate = false;
      },
      //世界知识产权组织
      handleCheckAllChangessssse(val) {
        this.property = val ? propertyOrganization.map(item => item.code) : [];
        this.propertyIndeterminate = false;
      },
      //亚洲
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.asiaCheckAll = checkedCount === this.asiaCity.length;
        this.asiaIsIndeterminate = checkedCount > 0 && checkedCount < this.asiaCity.length;
      },
      // 欧洲
      handleCheckedCitiesChanges(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.europeCheckAll = checkedCount === this.europeCity.length;
        this.europeIsIndeterminate = checkedCount > 0 && checkedCount < this.europeCity.length;
      },
      // 北美洲
      handleCheckedCitiesChangess(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.americaCheckAll = checkedCount === this.americaCity.length;
        this.americaIsIndeterminate = checkedCount > 0 && checkedCount < this.americaCity.length;
      },
      //南美洲
      handleCheckedCitiesChangesss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.southCheckAll = checkedCount === this.southCity.length;
        this.southIsIndeterminate = checkedCount > 0 && checkedCount < this.southCity.length;
      },
      //非洲
      handleCheckedCitiesChangessss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.africaCheckAll = checkedCount === this.africaCity.length;
        this.africaIsIndeterminate = checkedCount > 0 && checkedCount < this.africaCity.length;
      },
      //大洋洲
      handleCheckedCitiesChangesssss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.oceaniaCheckAll = checkedCount === this.OceaniaCity.length;
        this.oceaniaIsIndeterminate = checkedCount > 0 && checkedCount < this.OceaniaCity.length;
      },
      //世界知识产权组织
      handleCheckedCitiesChangessssse(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.propertyCheckAll = checkedCount === this.propertyOrganizationCity.length;
        this.propertyIndeterminate = checkedCount > 0 && checkedCount < this.propertyOrganizationCity.length;
      },
      //  查询蓝灯鱼当前登录用户的服务列表
      async searchServiceAjax () {
        let data = await this.$axios.get("/lantern/serviceLan/searchService");
        if (data.data.msg == "success") {
          if (data.data.data) {
            this.setTrialList(data.data.data)
            this.init();
          }
        }
      },
      // 去申请
      toApplyEvent () {
        if (!this.tool.getCookie("auth")) {
          this.dialogVisible = true;
        } else {
          this.$router.push({
            path: "/account/trialInfo",
            query: {
              serverType: 3
            }
          })
        }
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      init () {
        if (this.trialList) {
          for (var i = 0; i < this.trialList.length; i++) {
            if (this.trialList[i].serviceName == 0) {
              this.serviceNameType = this.trialList[i].serviceType
            }
          }
        }
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      blobToDataURL (blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
          callback(e.target.result);
        };
        a.readAsDataURL(blob);
      },
      queryDataUrl (data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      searchFirstFn () {
        //选择类别判断
        // this.isCategory = false
        // if(this.niceChecked && this.niceChecked.length != 0){
        //     this.isCategory = true
        // }else {
        //
        //   this.$alert('请选择一个或多个类别', '提示', {
        //     confirmButtonText: '确定',
        //     callback: action => {
        //       this.isCategory = false
        //     }
        //   });
        // }
        // if(this.isCategory == false){
        //   return;
        // }

        this.flag =  0;
        this.resultList = []
        this.choiceCountry = []
        //判断是否登录 未登录弹出登录提示框
        if(this.tool.getCookie("auth")){

        }else {
          this.dialogVisible = true
          return;
        }

        if(this.searchText){
          this.queryModes = "text";
        }else if(this.base64Url){
          this.queryModes = 'image'
        }

        //默认展示格式 表格还是图片
        if (this.queryModes) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }

        this.searchFn();
        this.calculation()
      },
      confirmSizer () {
        this.resultList = []
        // this.switchStyleDefault = true;
        if(this.searchText){
          this.queryModes = "text";
        }

        this.searchFn();
      },
      resetSizer () {
        this.niceList && this.niceList.map((item) => {
          item.isCheck = false;
        });
        this.tmStatusList.map((item) => {
          item.isCheck = false;
        });
        this.allChecked = false
      },
      firstChange () {
        //上一次点击是否包含 ‘图形’
        // let bolBefore = this.firstList[this.value].text.includes("图形");
        //当前是否包含‘图形’
        // return;
        // this.value = this.value;
        // let bolAfter = this.firstList[this.value].text.includes("图形");
        //判断当前点击的是不是图形
        // if (bolAfter) this.queryModes = "image";
        // else {
        //   this.queryModes = "text";
        // this.base64Url = "";
        // }
        // if (!bolBefore && !bolAfter) this.searchFirstFn();
      },
      moreFn (who) {
        this.moreObj[who] = !this.moreObj[who];
      },
      searchFn () {
        if (this.searchText && this.queryModes != "image") {
          this.boxShow = false
          this.isRotate = true
          this.firstChange()
          // if (this.queryModes) {
          let regex = new RegExp("；", "g"),
            regexSemi = new RegExp("^[;]+|[;]+$", "g");
          let searchTextArr = this.searchText.replace(regex, ";").replace(regexSemi, "").split(";");
          let isAppNo = new RegExp(/^[gGaA]?[0-9]+[gGaA]?$/);
          if (searchTextArr && searchTextArr.length != 0) {
            for (var i = 0; i < searchTextArr.length; i++) {
              //不是申请号判断
              if (!isAppNo.test(searchTextArr[i])) {
                // this.value = 2;
                this.pageNo = 1;
                this.pageNos = 1;
                // this.queryModes = "text";
                // this.querySearch_FromDB();
                this.getJurisdictionAjax();
                return;
              } else {
                //申请号近似
                this.value = 5;
              }
            }
          } else {
            if (isAppNo.test(this.searchText)) {
              this.value = 5;
            }
            // else {
            //   this.value = 2;
            // }
          }
          // }
          this.highGradeQuery()
          this.pageNo = 1;
          this.pageNos = 1;
          // this.queryModes = "text";
          // this.querySearch_FromDB();
          this.getJurisdictionAjax();
        } else if (this.queryModes == "image" && this.base64Url) {
          this.highGradeQuery()
          this.firstChange()
          this.pageNo = 1;
          this.pageNos = 1;
          // this.queryModes = "image";
          // this.graphSearch_FromDB()
          //图片查询
          this.getJurisdictionAjaxImage();
        } else {
          if(this.searchText && this.queryModes == "image"){
            this.$message($t(`PQ.Tips[0]`));
          }else if(this.base64Url && this.queryModes == "text") {
            this.$message($t(`PQ.Tips[1]`));
          }else {
            this.$message($t(`PQ.Tips[2]`));
          }

        }
        //文本查询
        /* this.resultList = [
           {level: '高'},
           {level: '中'},
           {level: '低'},
         ];*/
      },
      goDetail (item) {
        // $(window).unbind('scroll', this.scrollFn);
        if(item.country == "CN"){
          window.open('#/query/detail' + '?id=' + item.id + '&country=' + item.country);
        }else {
          window.open('#/query/otherTrademarkArchives' + '?id=' + item.id + '&country=' + item.country);
        }
      },
      aLargerPreview (item) {
        window.open(this.queryDataUrl(item.imageUrl));
        this.resultList.map(item => item.showBig = false);
        item.showBig = true;
      },
      scrollFn () {

        let scrollTop = $(window).scrollTop();    //滚动条距离顶部的高度
        let scrollHeight = $(document).height();   //当前页面的总高度
        let clientHeight = $(window).height();    //当前可视的页面高度
        if (scrollTop + clientHeight + 170 >= scrollHeight) {   //距离顶部+当前高度 >=文档总高度 即代表滑动到底部
          //滚动条到达底部
          let numMax = this.pagesTotal;
          let num = this.pageNos;
          if (num < numMax && this.resultList.length) {//this.resultMarks.length防止
            num++;
            this.loadMore = "加载中......";
            this.handleCurrentChange(num);
          } else {
            this.loadMore = "加载完毕";
          }
          $(window).unbind('scroll', this.scrollFn);
        } else if (scrollTop <= 0) {
          //滚动条到达顶部
          //滚动条距离顶部的高度小于等于0 TODO
        }
      },
      handleCurrentChange (val) {
        this.pageNos = val;
        this.highGradeQuery()
        // this.switchStyleDefault = true;
        switch (this.queryModes) {
          case "image": {
            //   this.queryModes = "image";
            //   this.graphSearch_FromDB()
            this.getJurisdictionAjaxImage();
          }
            break;
          case "text": {
            //   this.querySearch_FromDB();
            this.getJurisdictionAjax();
          }
            break;
        }
      },
      // 更新是否是第一次使用服务的状态
      async updateIsFirstClickAjax () {
        let data = await this.$axios.put("/lantern/serviceLan/updateIsFirstClick", {
          params: {
            serviceName: 0
          }
        })
      },
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjax () {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryModes = "text";
          this.querySearch_FromDB();
          this.$axios.put("/lantern/trademarkSearchLan/updateCount")
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            //   if (data.data.data.count == "100") {
            //     this.$confirm(this.$t('patentSearch.confirmTitle2'), this.$t('patentSearch.tips'), {
            //       confirmButtonText: this.$t('patentSearch.goLogin'),
            //       cancelButtonText: this.$t('patentSearch.cancelBtn'),
            //       type: 'warning'
            //     }).then(() => {
            //       this.dialogVisible = true;
            //     }).catch(() => {
            //     })
            //   } else {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      //查找code 对应 国家名字
      code_country(code){
        return this.allCountry.filter(item => item.code == code)[0].city
      },
      /*文字查詢*/
      querySearch_FromDB () {
        if (!this.searchText) return;
        //   this.$nuxt.$loading.start()
        this.queryModes = "text";
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        this.$axios.post('/common/query/search/v2', {
          current: this.pageNos,
          keywork: this.value == 2 || this.value == 4 ? this.searchText : undefined,
          size: this.value == 6 ? 5000 : this.pageSize,
          // niceList: this.niceChecked || undefined,
          // param: this.value !== ""  ? this.firstList[this.value].param : undefined,
          // appDate: this.value == 1 ? undefined : "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          agent: this.value == 6 ? this.searchText : undefined,
          applicant: this.value == 0 || this.value == 3 ? this.queryItem.tmApplicant.value ? this.queryItem.tmApplicant.value : this.searchText : undefined,
          appNo: this.value == 1 || this.value == 5 ? this.queryItem.applicationNumber.value ? this.queryItem.applicationNumber.value.replace(/[.]+/g, '').replace(/[，,；;]+/g, ';') : this.searchText : undefined,
          queryWay: this.value !== "" && this.value !== 6 ? this.firstList[this.value].queryWay : undefined,
          // "group":true,
          secondQuery: this.flag ? {...this.conditions, country: this.stateChange} : undefined,
          ...this.queryCriteria
        }).then(({ data }) => {
          // this.$nuxt.$loading.finish()
          if (data.code != 0) {
            this.isCN ? this.$confirm('您的查询次数已用完', this.$t(`patentSearch.tips`), {
              confirmButtonText: '去购买',
              cancelButtonText: this.$t(`patentSearch.cancelBtn`),
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/personalCenter/productService",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            }) : this.$confirm('The number of queries has been used up', 'Tips', {
              confirmButtonText: 'Yes',
              // cancelButtonText: '取消',
              showCancelButton: false,
              type: 'warning'
            }).then(() => {

            }).catch(() => {

            });
          } else {
            this.condition = this.searchText
            this.allResult = data.data
            this.searchHandle(this.getClone(data.data));
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      //
      getClone(data){
        return JSON.parse(JSON.stringify(data))
      },
      /*文字查詢----分析报告*/
      querySearch_FromDBs () {
        if (!this.searchText) return;
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0,
          }
        })
        //   this.$nuxt.$loading.start()
        this.queryModes = "text";
        this.$axios.post('/common/query/analyzeSuccessRate', {
          current: this.pageNo,
          keywork:  this.value == 2 || this.value == 4 ? this.searchText : undefined,
          size: this.pageSize,
          // niceList: this.niceChecked || undefined,
          param: this.value !== ""  ? this.firstList[this.value].param : undefined,
          appDate: this.value == 1 ? undefined : "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          agent: this.value == 6? this.searchText : undefined,
          applicant: this.value == 0 || this.value == 3  ? this.searchText : undefined,
          appNo: this.value == 1 || this.value == 5 ? this.searchText : undefined,
          queryWay: this.value !== "" && this.value !== 6? this.firstList[this.value].queryWay : undefined,
          secondQuery: this.flag ? {...this.conditions, country: this.stateChange} : undefined,
          ...this.queryCriteria
        }).then(({ code, data, msg }) => {

          if(data.code === 0){
            localStorage.setItem('data', JSON.stringify({
              data:data.data,
              type:1,
              name:this.searchText
            },));
            let routeUrl = this.$router.resolve({
              path: `/analysisReport`
            });
            window.open(routeUrl .href, '_blank');
          }else {
            this.$message.error('生成可注册报告失败');
          }

        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      /*图片查询-------分析报告*/
      graphSearch_FromDBs () {
        if (!this.base64Url) return;
        this.selectedCountries = this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        //   this.$nuxt.$loading.start()
        this.queryModes = "image";
        this.value = 4;
        this.$axios.post('/common/query/analyzeSuccessRate', {
          current: this.pageNo,
          size: this.pageSize,
          base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
          imageCodeList: this.imageCodeList || undefined,
          // niceList: this.niceChecked || undefined,
          param: undefined,
          selectedArea: this.selectedArea || undefined,
          appDate: this.value == 4 ? undefined : "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          secondQuery: this.flag ? {...this.conditions, country: this.stateChange} : undefined,
          ...this.queryCriteria,
        }).then(({ code, data, msg }) => {
          if(data.code === 0){
            localStorage.setItem('data', JSON.stringify({
              data:data.data,
              type:2,
              name:this.base64Url
            },));
            let routeUrl = this.$router.resolve({
              path: `/analysisReport`
            });
            window.open(routeUrl .href, '_blank');
          }else {
            this.$message.error('生成可注册报告失败');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },

      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjaxImage (params) {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryModes = "image";
          this.graphSearch_FromDB()
          this.$axios.put("/lantern/trademarkSearchLan/updateCount");
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            //   if (data.data.data.count == "100") {
            //     this.$confirm(this.$t('patentSearch.confirmTitle2'), this.$t('patentSearch.tips'), {
            //       confirmButtonText: this.$t('patentSearch.goLogin'),
            //       cancelButtonText: this.$t('patentSearch.cancelBtn'),
            //       type: 'warning'
            //     }).then(() => {
            //       this.dialogVisible = true;
            //     }).catch(() => {
            //     })
            //   } else {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      /*图片查询*/
      graphSearch_FromDB () {
        if (!this.base64Url) return;
        this.selectedCountries = this.stateChange && this.stateChange.split(';').map(item => {
          return {
            name: item,
            countryName: this.code_country(item),
            value: 0
          }
        })
        //   this.$nuxt.$loading.start()
        this.queryModes = "image";
        this.value = 4;
        this.$axios.post('/common/graph/search/v2', {
          current: this.pageNos,
          size: this.pageSize,
          base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
          imageCodeList: this.imageCodeList || undefined,
          // niceList: this.niceChecked || undefined,
          param: undefined,
          selectedArea: this.selectedArea || undefined,
          appDate: this.value == 4 ? undefined : "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange,
          lang: "zh",
          // "group":true,
          ...this.queryCriteria,
          secondQuery: this.flag ? {...this.conditions, country: this.stateChange} : undefined
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          if (data.code != 0) {
            this.isCN ? this.$confirm('您的查询次数已用完', this.$t('patentSearch.tips'), {
              confirmButtonText: '去购买',
              cancelButtonText: this.$t('patentSearch.cancelBtn'),
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/personalCenter/productService",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            }) : this.$confirm('The number of queries has been used up', 'Tips', {
              confirmButtonText: 'Yes',
              // cancelButtonText: '取消',
              type: 'warning',
              showCancelButton: false,
            }).then(() => {

            }).catch(() => {

            });
          } else {
            this.condition = this.base64Url
            this.allResult = data.data
            this.searchHandle(this.getClone(data.data));
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      /*文字或图片查询的后续处理*/
      searchHandle (data, datas) {
        this.value == 5 || 3 || 6 ? this.isapplication = true : this.isapplication = false
        this.choiceCountry && this.choiceCountry.length != 0 ? this.partResult = this.getClone(datas) : this.partResult = this.getClone(data)

        this.partResult && this.partResult.records.map(item => {
          this.dataType.forEach(items => {
            if(item.intcls == items.type) item.miaoSu = items.name
          })
          item.alias = subStatusObj[item.tmstatus]
          return item
        })
        this.isReport = this.stateChange
        this.selectedCountries = this.selectedCountries && this.selectedCountries.map(item => {
          this.partResult.countryList && this.partResult.countryList.map(items => {
            if (this.value == 6 && item.name == items.name) {
              item.value = this.partResult.total
            } else if (item.name == items.name) {
              item.value = items.value
            }
          })
          return item;
        })

        this.responseData = this.partResult
        if (this.partResult && this.partResult.tmstatuscodeList && this.partResult.tmstatuscodeList.length ) {
          this.partResult.tmstatuscodeList = this.partResult && this.partResult.tmstatuscodeList && this.partResult.tmstatuscodeList.map(item => {
            return {
              ...item,
              statusName: statusObj[item.name]
            }
          })
        }
        if(this.pageNos == 1){
          this.arr = []
          this.newData = []
          this.resultList = []
          this.allType = this.partResult
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }


        $(window).unbind('scroll', this.scrollFn);
        $(window).scroll(this.scrollFn);
        this.allType && this.allType.records && this.allType.records.map(item => {
          item.showName = item.tmapplicant || item.tmapplicant_en || item.applicant_en || item.applicantEn;
          item.showAdress = item.tmaddress || item.tmaddress_en || item.address_en || item.addressEn;
          // item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
          item.showTrademarkName = (item.tmcn && item.tmen ? item.tmcn + "--" + item.tmen : item.tmcn || item.tmen);

          // 服务平台图片近似查询结果评分标准：
          // score<=85 高，
          // score>85 and score<=250 中，
          // score>250 低，

          // 服务平台文字近似查询结果评分标准：
          // score>=80 高，
          // score>=20 and score<80 中，
          // score<20 低

          let level = "";
          if (this.queryModes == 'image') {
            if (parseInt(item.score) <= 85) level =  !this.isCN ? 'High' : '高';
            else if (parseInt(item.score) > 85 && parseInt(item.score) <= 250) level = !this.isCN ? 'Middle' : '中';
            else if (parseInt(item.score) > 250) level = !this.isCN ? 'Low' : '低';
          } else if (this.queryModes == 'text') {
            if (parseInt(item.score) >= 80) level = !this.isCN ? 'High' : '高';
            else if (parseInt(item.score) >= 20 && parseInt(item.score) < 80) level = !this.isCN ? 'Middle' : '中';
            else if (parseInt(item.score) < 20) level = !this.isCN ? 'Low' : '低';
          }
          item.level = level;

          // if (item.type != 'tm_info') {
          //   try {
          //     if (item.tmdetailEn.indexOf("niceCode\\\\\":") != -1) {
          //       item.tmdetailEn = item.tmdetailEn.replaceAll("niceCode\\\\\":", "niceCode\\\\\"");
          //     }
          //     item.tmdetail = JSON.parse(item.tmdetailEn || "[]");
          //     item.tmdetail = item.tmdetail.map(item2 => `${item2.niceCode}:${item2.value}`).join(";");
          //   } catch (e) {
          //     item.tmdetail = item.tmdetail_en;
          //   }
          // }

          //如果选择的国家是中国大陆
          if(item.country == 'CN'){
            if(item.tmdetail){
              item.showTmdetail = item.tmdetail
            }
            else item.showTmdetail = ''
          }else {
            if(item.tmdetailEn ){
              //for(var key in JSON.parse(item.tmdetailEn)[0]){
              //item.showTmdetail = JSON.parse(item.tmdetailEn)[0][key]
              //}
            }
            else item.showTmdetail = ''
          }
          // item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
        });

        //选择了申请类别 筛选----
        if(this.allType.records && this.niceChecked && this.niceChecked.split(';').length != 0 && this.pageNos == 1){
          // this.allType.records.map((item,index) => {
          //   //全类数据包含了要筛选的数据
          //   if(this.niceChecked.split(';').includes(item.intcls)){
          //     //把它push到数组中
          //     this.arr.push(item);
          //     //删除原数组
          //     this.allType.records.splice(index,1)
          //   }
          // })
          for(let i = 0 ; i < this.allType.records.length ; i++){
            //全类数据包含了要筛选的数据
            for(let j = 0 ; j < this.allType.records[i].intcls.split(';').length ; j++){
              if(this.niceChecked.split(';').includes(this.allType.records[i].intcls.split(';')[j])){
                //把它push到数组中
                this.arr.push(this.allType.records[i]);
                //删除原数组
                this.allType.records.splice(i,1)
                i--;
                break;
              }
            }
            //全类数据包含了要筛选的数据
            // if(this.niceChecked.split(';').includes(this.allType.records[i].intcls)){
            //   //把它push到数组中
            //   this.arr.push(this.allType.records[i]);
            //   //删除原数组
            //   this.allType.records.splice(i,1)
            //   i--;
            // }
          }

        }

        this.noData = false
        if(this.niceChecked && this.arr && this.arr.length  == 0){
          this.noData = true
        }else if(this.niceChecked && this.arr && this.arr.length < 10 && this.arr.length < this.allType.total){
          // this.$message({
          //   message: '结果较少，以下为其他类别结果供参考',
          //   type: 'warning'
          // });
          // this.noData = false
          this.arr[this.arr.length-1].tips = true
          this.arr[this.arr.length-1].hint = '结果较少，以下为其他类别结果供参考'
        }else if(this.niceChecked && this.arr.length < this.allType.total) {
          // this.$message({
          //   message: '以下为其他类别结果供参考',
          //   type: 'warning'
          // });
          // this.noData = false
          this.arr[this.arr.length-1].tips = true
          this.arr[this.arr.length-1].hint = '以下为其他类别结果供参考'
        }


        localStorage.setItem("douxing", JSON.stringify(this.arr.concat(this.allType.records).map(item => item.id)));
        localStorage.setItem("douguo", JSON.stringify(this.arr.concat(this.allType.records).map(item => item.country)));
        this.newData = this.arr.concat(this.allType.records).slice((this.pageNos - 1)*20,this.pageNos*20)
        this.resultList = this.pageNos == 1 ? this.newData : this.resultList.concat(this.newData);
        this.$set(this.resultList, 'checked', false)
        this.total = this.allType.total;
        this.pagesTotal = this.allType.total/20;


        this.resultBool = true;
        // if (this.switchStyleDefault == false) {
        //   if (this.queryModes == "image") {
        //     this.switchStyle = true;
        //   } else if (this.queryModes == "text") {
        //     this.switchStyle = false;
        //   }
        // }
        return;
        this.calculation()
      },
      inputFn () {
        if (this.value == 4) this.value = 2;
        if (this.value === "") this.value = 2;

      },
      handleRemove () {
        this.base64Url = "";
        this.$refs.upload.clearFiles();
      },
      uploadChangeFn (file, fileList) {
        if (fileList && fileList.length > 1) {
          fileList.splice(0, 1);
        }
        this.blobToDataURL(file.raw, (base64) => {
          this.base64Url = base64;
          this.pageNo = 1;
          this.pageNos = 1;
          this.value = 4;
          this.searchText = "";
          // this.queryModes = "image";
          // this.graphSearch_FromDB()

          // this.getJurisdictionAjaxImage();
        });

        return;
        this.upload_({ file: file.raw })
          .then(({ code, data, msg }) => {
            if (code) {
              this.$message(msg);
              return;
            }
            this.base64Url = data;
            this.getJurisdictionAjaxImage();
          })
      },
      // 下载
      downEvent () {
        //   this.$nuxt.$loading.start()
        this.$axios.post('common/query/exportExcel', {
          keywork: this.searchText,
          queryWay: this.value !== "" ? this.firstList[this.value].queryWay : undefined,
          // niceList: this.niceChecked || undefined,
          param: this.value !== "" ? this.firstList[this.value].param : undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.stateChange
        }, {
          responseType: 'blob'
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '查询结果.xls'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      }
    },
    computed: {
      ...mapState("query", [
        "queryItemsShow",
        "queryData",
        "ajaxResponse",
        "othersItem",
        "pageNum_",
        "pageSize_",
        "noReset",
        "queryBtnClickNum",
        "statusList",
        "countryStatusVuex",
        'me',
        'trialList'
      ]),
      ...mapGetters("query", ["classifyChoosed", "classifyStatus"]),
      //筛选条件
      conditions(){
        let asideForms = {}
        for(let k in this.asideForms){
          asideForms[k] =  this.asideForms[k].join(';')
        }
        return asideForms;
      },
      viennaChoosedStr() {
        let imageType = "";
        if(this.queryData.viennaChoosedArr){
          this.queryData.viennaChoosedArr.map((item, index) => {
            imageType +=
              item +
              (index == this.queryData.viennaChoosedArr.length - 1 ? "" : ";");
          });
          this.queryItem.patternElement.value = imageType;
        }
        return imageType;
      },
      niceChoosedStr() {
        /*0124临时代码*/
        let imageType = "";
        this.queryData.classify.map((item, index) => {
          if (item.isCheck)
            imageType += item.no + (index == this.niceChoosedArr.length - 1 ? "" : ";");
        });
        this.queryItem.classify.value = imageType;
        return imageType;
      },
      ...['country'].reduce((obj, cur) => {
        obj[queryParamsMap[cur]] = {
          get() {
            return this.$store.state.query.queryData[cur]
          },
          set(v) {
            // 使用vuex中的mutations中定义好的方法来改变
            this.queryDataFn({...this.$store.state.query.queryData,[cur]:v})
          }
        }
        return obj;
      }, {}),
      //----------
      files(){
        return this.base64Url
      },
      index1(){
        return this.asia.length
      },
      index2(){
        return this.europe.length
      },
      index3(){
        return this.america.length
      },
      index4(){
        return this.south.length
      },
      index5(){
        return this.africa.length
      },
      index6(){
        return this.oceania.length
      },
      index7(){
        return this.property.length
      },
      niceChecked () {
        return this.niceList && this.niceList.filter(item => item.isCheck).map(item2 => item2.categoryNo).join(";");
      },
      tmStatusChecked () {
        return this.tmStatusList.filter(item => item.isCheck).map(item2 => item2.code).join(";");
      },
      //所有国家
      allCountry(){
        return  this.$t(`PQ.cityOptions`).concat(this.$t(`PQ.city`),this.$t(`PQ.cityOptionsd`),this.$t(`PQ.cityOptionsds`),this.$t(`PQ.cityOpts`),this.$t(`PQ.cityds`),this.$t(`PQ.propertyOrganization`))
      },
      //国家国旗
      nationalFlag(){
        let str = ''
        str = this.asia.concat(this.europe,this.america,this.south,this.africa,this.oceania,this.property) && this.asia.concat(this.europe,this.america,this.south,this.africa,this.oceania,this.property).length > 1 ? '/images/national/colorGol.png' : this.allCountry.map(item => {
          if(item.code == this.asia.concat(this.europe,this.america,this.south,this.africa,this.oceania,this.property)[0])
            return item.ingSrc
        }).join('')
        return str
      },
      //国家个数发生改变
      stateChange(){
        return this.asia.concat(this.europe,this.america,this.south,this.africa,this.oceania,this.property).join(';')
      }
    },
    watch: {
      //choiceCountry
      choiceCountry:{
        handler(newValue,oldValue){

          if(this.allResult && this.allResult.records  && newValue && newValue.length != 0){
            if(this.allResult.records && this.allResult.records.length != 0){
              let arr = JSON.parse(JSON.stringify(this.allResult))
              arr.records = this.allResult.records.filter(item => newValue.includes(item.country))
              this.searchHandle([],arr)
            }
          }else {
            if(this.allResult && this.allResult.records && this.allResult.records.length) this.searchHandle(this.allResult,[])
          }
        },
        deep: true
      },
      //searchText 监控
      // searchText(newValue, oldValue){
      //   if(newValue){
      //     this.queryDirection = false
      //     this.value = 2
      //     this.value = 2
      //   }else {
      //     this.queryDirection = true
      //   }
      // },
      //base64Url
      // base64Url(newValue, oldValue){
      //   if(newValue){
      //     this.queryDirection = false
      //     this.value = 4
      //     this.value = 4
      //   }else {
      //     this.queryDirection = true
      //   }
      // },
      allChecked(newValue, oldValue){
        if(newValue == true){
          this.niceList && this.niceList.map(item => {
            return item.isCheck = true
          })
        }else {
          this.niceList && this.niceList.map((item) => {
            item.isCheck = false;
          });
          this.tmStatusList.map((item) => {
            item.isCheck = false;
          });
        }
      },
      boxShow(news,old){
        if(news && screen.width <= 1600 && !this.queryModes){
          document.getElementsByClassName('query040')[0].style.minHeight = 'calc(100vh - -240px)';
        }else {
          document.getElementsByClassName('query040')[0].style.minHeight = 'calc(100vh + 53px)';
          if(screen.height == 1080 && document.body.offsetHeight < 930 && !this.queryModes){
            document.getElementsByClassName('query040')[0].style.minHeight = 'calc(100vh - 20px)';
          }else {
            document.getElementsByClassName('query040')[0].style.minHeight = 'calc(100vh + 53px)';
          }
        }

        if(!news){
          document.getElementsByClassName('query040')[0].style.minHeight = 'calc(100vh - 130px)';
        }
      },
      $route () {
        this.tag = this.$route.query.tag
      },
      tag (val) {
        if (val == 1 || val == undefined) {
          //浏览器刷新页面
          this.$router.go(0);
        }
      },
      queryModes (val) {
        if (val != "") {
          this.tag = 2;
        }
      }
    }
  }
</script>
<style>
  @keyframes riverHorse_query {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: translate3d(0, -380px, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(0, 8px, 0);
    }

    75% {
      opacity: 1;
      transform: translate3d(0, -20px, 0);
    }

    90% {
      opacity: 1;
      transform: translate3d(0, 5px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes searchInputAppear_query {
    from {
      width: 0;
    }
    to {
      width: 960px;
    }
  }

  @keyframes camera_query {
    from {
      font-size: 0px;
    }
    to {
      font-size: 38px;
    }
  }

  @keyframes cameraImg {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
</style>
<style lang="less">
  .high-grade-query {
    &.high-grade-query-en {
      .query-item {
        /*margin-bottom: 0;*/
        & > * {
          display: block;
        }

        .title {
          width: 100%;
          height: 32px;
          line-height: 32px;
        }

        .container {
          width: 100%;

          .choose {
            width: 100%;

            .leftText {
              width: 500px;
              height: 100%;
              font-size: 14px;
            }

            .rightText {
              width: 130px;
              text-align: center;
            }
          }

          .el-date-editor-long-officialt.el-date-editor {
            width: 300px;

            &.is-disabled {
              background: #eeeeee;
            }
          }
        }
      }
    }
    width: 660px;
    margin: 0 auto;
    padding-bottom: 30px!important;
    /*margin-top: 50px;*/
    .toHigh, .toFast {
      margin: 0 auto;
      /*width: 134px;*/
      cursor: pointer;
      & > * {
        display: inline-block;
      }
      &.mt320 {
        margin-top: 327px;
      }
      .el-icon-arrow-right, .el-icon-arrow-left {
        width: 24px;
        height: 24px;
        background: #cfcfcf;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        vertical-align: middle;
      }
      i:hover{
        background-color: #3a6ecf;
      }

      span {
        font-size: 16px;
        letter-spacing: 1px;
        color: #333333;
        vertical-align: middle;
      }
    }
    .toFast {
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 2;
    }
    .toFast:hover{
      i{
        background-color: #3a6ecf;
      }
      span{
        color:#3a6ecf
      }
    }
    .query-item {
      font-size: 0;
      margin-bottom: 15px;
      position: relative;
      .el-icon-delete{
        &:hover{
          color: red !important;
        }
      }
      & > * {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
      }

      .title {
        width: 136px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333333;
        .el-input--suffix {
          .el-input__inner {
            border-radius: 4px 0 0 4px;
          }
        }
      }

      .container {
        width: 520px;
        .el-input__inner {
          border-radius: 0 4px 4px 0;
          height: 40px;
        }
        .el-radio-group{
          display: flex;
          align-items: center;
          height: 40px;

          margin-left: 15px;
        }
        .el-select{
          .el-input--suffix{
            width: 520px;
          }
        }
        .queryModes {
          margin-top: 12px;
        }

        .upload-drag {
          border: 1px solid #d8dce5;
          height: 100px;

          &.uploadDisabled {
            position: relative;
            border: 0;

            .el-upload {
              background: #eeeeee;

              .el-upload-dragger {
                p {
                  color: #c0c4cc;
                }

                .el-upload-disabled-shade {
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  /*background: rgba(255, 238, 245, 0.6);*/
                  top: 0;
                  right: 0;
                  cursor: not-allowed;
                }
              }
            }
          }

          .el-upload {
            width: 100%;
            height: 100%;
            background: transparent;

            .el-upload-dragger {
              border: none !important;
              width: 100%;
              height: 100%;
              background: transparent;
              position: relative;
              /*padding: 10px;*/
              .upload-btn {
                width: 38px;
                height: 36px;
                margin-top: 10px;
              }

              .upload-img {
                height: 96%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
              }

              p.delete {
                position: absolute;
                z-index: 5;
                right: 10px;
              }

              p {
                margin-top: 5px;
                font-size: 12px;
                letter-spacing: 1px;
                color: #999999;

                &.delete:hover {
                  color: #0b6ce8;
                }
              }

              .el-upload-disabled-shade {
                display: none;
              }
            }
          }
        }

        /*图形要素呢内*/
        .choose {
          /*background: #eeeeee;*/
          /*border: 1px solid #d8dce5;*/
          width: 500px;
          height: 36px;
          border-radius: 5px;
          font-size: 0;
          /*padding: 5px 0px;*/
          line-height: 36px;
          font-size: 0;

          & > * {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            letter-spacing: 1px;
          }

          span.leftText {
            width: 436px;
            background: transparent;
            font-size: 12px;
            height: 36px;
            display: inline-block;
            padding: 0 15px;
            line-height: 36px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .rightText {
            font-size: 14px;
            color: #666666;
            /* height: 100%; */
            width: 65px;
            margin-left: 5px;
            line-height: 34px;
            border-radius: 3px;
            margin-top: 2px;
          }
        }

        .date {
          font-size: 0;

          & > * {
            font-size: 12px;
            vertical-align: middle;
          }

          .to {
            width: 40px;
            display: inline-block;
            text-align: center;
            font-size: 14px;
            letter-spacing: 1px;
            color: #333333;
          }
        }
      }
    }
    .buttons {
      overflow: hidden;
      text-align: left;

      .el-checkbox {
        margin-left: 40px;
      }

      .el-button {
        float: right;

        &.return-btn {
          margin-right: 20px;
        }
      }
    }
    .long-dialog-old.el-dialog__wrapper {
      text-align: center;

      .el-dialog {
        width: auto;
        display: inline-block;
      }

      .el-dialog__header {
        padding: 0;
        height: 0px;
        display: inline-block;

        .el-icon-close {
          top: 0;
          right: 0;
          font-size: 20px;
        }
      }

      .el-dialog__body {
        padding: 0;
        display: inline-block;
      }

      .long-inner {
        width: 740px;
        border-radius: 30px;
        text-align: left;

        &.long-inner-viena {
          width: 950px;
        }

        .top {
          height: 53px;
          line-height: 53px;
          font-size: 14px;
          letter-spacing: 1px;
          color: #666666;
          padding: 0px 50px;
          border-bottom: 1px solid #cecece;
          background-color: #efefef;
          border-radius: 3px;

          i {
            color: #e96364;
          }

          span {
            margin-left: 12px;
          }
        }

        /*尼斯分类*/
        .middle.nice-style {
          height: 530px;
          overflow: auto;

          .first-ul {
            font-size: 13px;

            .first-li {
              padding: 0;
              text-indent: 50px;

              .first-p {
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                letter-spacing: 0px;
                color: #333333;

                &:hover {
                  background: #f7f7f7;
                }

                .el-checkbox {
                  text-indent: 0;
                  //复选框样式见long.scss
                }
              }

              .second-ul {
                .second-li {
                  padding: 0;
                  text-indent: 50+18px;

                  &:nth-last-of-type(1) {
                    padding-bottom: 0;
                  }

                  .second-p {
                    cursor: pointer;
                    height: 34px;
                    line-height: 34px;

                    &:hover {
                      background: #f7f7f7;
                    }

                    .el-checkbox {
                      text-indent: 0;
                      //复选框样式见long.scss
                    }
                  }
                }
              }
            }
          }
        }

        .bottom {
          height: 94px;
          padding: 30px 54px;
          text-align: right;

          .el-button {
            height: 30px;
            width: 100px;
          }

          .viennaConfirm {
          }

          .viennaClose {
            margin-left: 22px;
          }
        }
      }
    }
    .el-input-long-officialt {
      width: 500px;
      height: 40px;
      border-radius: 0 4px 4px 0;

      &.is-disabled {
        input.el-input__inner {
          background-color: #eeeeee;
          border-color: #eeeeee;
          color: #c0c4cc;
          cursor: not-allowed;
        }
      }
    }
    .el-date-editor-long-officialt.el-date-editor {
      width: 230px;
      &.is-disabled {
        input.el-input__inner {
          background-color: #eeeeee;
          color: #c0c4cc;
          border: 0;
        }
      }
    }

    .el-select-long-officialt {
      width: 500px;
    }

    .el-long-white-button {
      &:hover {
        span {
          color: #ffffff;
        }
      }

      &.is-disabled {
        span {
          color: #c0c4cc;
        }
      }
    }

    .el-input {
      &.leftText {
        width: 430px;

        input {
          width: inherit;
          vertical-align: top;
          height: 40px;
          line-height: 40px;
          border: 0;
          background: transparent;
          border: 1px solid #d8dce5;
        }
      }

      /*.leftText .el-input__inner:focus{
                  border:1px solid #409eff!important;
              }*/
    }

    /*0124尼斯分类*/
    /*    section.classify {
            width: 100%;
            & > div {
              margin-left: 5px;
              width: 740px;
              height: 440px;
              padding: 35px 0 57px 0px;
              background-color: #ffffff;
              box-shadow: 0px 2px 18px 0px rgba(47, 47, 47, 0.18);
              border-radius: 5px;
              ul {
                text-align: left;
                li {
                  display: inline-block;
                  height: 35px;
                  width: 98px;
                  margin-left: 42px;
                  line-height: 35px;
                  font-size: 13px;
                  cursor: pointer;
                  color: #333333;
                  &:hover {
                    color: #eb4142;
                  }
                  &.checked {
                    color: #e25665;
                  }
                }
              }
              .buttons {
                margin-top: 25px;
                padding-right: 68px;
                overflow: hidden;
                .el-button {
                  float: right;
                  width: 90px;
                  height: 34px;
                  padding: 0;
                  font-size: 16px;
                  border-radius: 5px;
                  !*确定按钮*!
                  &:nth-of-type(1) {
                    border: solid 1px transparent;
                    background-color: #d01027;
                    color: #ffffff;
                    &:hover {
                      background-color: #c6293a;
                    }
                  }
                  !*取消按钮*!
                  &:nth-of-type(2) {
                    border: solid 1px #aaaaaa;
                    margin-right: 20px;
                    &:hover {
                      color: #ffffff;
                      background-color: #434554;
                    }
                  }
                }

              }
            }
          }*/
    .class-in-the-Nice-classification.high-classify {
      /*有序号有内容的时候*/
      /*      &.en {
                & > div {
                  padding: 35px 0 20px 0px;
                  ul {
                    height: 450px;
                  }
                }
              }*/
    }
  }

  .el-input-long-officialt input.el-input__inner:focus {
    border: 1px solid #409eff;
  }
  .powerByLandengyu {
    position: absolute;
    bottom: 40px;
    left: 45%;
    transform: translateX(-40%);
    color: #999;
    .data-desription {
      text-align: center;
      font-size: 12px;
      margin-bottom: 8px;
      span {
        cursor: pointer;
        color: #4c92d8;
      }
      .left {
        margin-right: 12px;
      }
    }
    .disclaimer{
      width: 840px;
      margin: 0 auto;
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 8px;
      text-align: center;
    }
    .container {
      width: 840px;
      text-align: center;
      font-size: 0;
      & > * {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
      span{
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #4c92d8;
        margin: 0 2px;
      }
      img{
        height: 26px;
      }
    }
    .container span:hover {
      color: rgb(64, 187, 224);
      border-bottom: 1px solid rgb(64, 187, 224);
    }

  }
</style>

<style lang="less">
  .screen{
    .header-icon{
      width: 11px;
      height: 11px;
      margin-right: 13px;
      margin-left: 70px;
      background: #1A3DBF;
      border-radius: 50%;
    }
    .el-collapse-item__arrow{
      margin: 0 43px 0 auto;
      color: #65B3F2;
    }
    .el-collapse:nth-of-type(1){
      border-top: 1px solid rgba(49,164,255,0.35);
    }
    .el-collapse-item__header{
      border-bottom: 1px solid rgba(49,164,255,0.35);
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      white-space: nowrap;
      background: #E7F3FC;
    }
    .el-checkbox-group{
      padding-left: 60px;
    }
    .el-collapse-item__wrap{
      background: #E7F3FC;
    }
    .el-collpase{
      background: #E7F3FC;
    }
    .el-checkbox-group{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
</style>

<style lang="less" scope>
  .boxCard{
    /deep/.is-checked{
      visibility: visible!important;
    }

    /deep/.el-checkbox__input{
      /*visibility: hidden;*/
    }

    /deep/.is-indeterminate{
      visibility: visible!important;
    }

    /deep/.el-checkbox__label{
      font-size: 12px;
    }

    /deep/.el-card__body{
      padding: 28px 37px 28px 37px;
      box-sizing: border-box;
    }
    /deep/.el-card__body{
      padding: 28px 37px 28px 37px;
      box-sizing: border-box;
    }
    position: fixed;
    top: 406px;
    left: 45.8%;
    @media screen and (max-width: 1600px) {
      /*1280*/
      left: 45%;
    }
    @media screen and (max-width: 1440px) {
      /*1280*/
      left: 44.5%;
    }
    @media screen and (max-width: 1366px) {
      /*1280*/
      left: 44%;
    }
    @media screen and (max-width: 1280px) {
      /*1280*/
      left: 43.8%;
    }

    &.active{
      left: 43%;
      @media screen and (max-width: 1600px) {
        /*1280*/
        left: 41.5%;
      }
      @media screen and (max-width: 1440px) {
        /*1280*/
        left: 40.5%;
      }
      @media screen and (max-width: 1366px) {
        /*1280*/
        left: 40%;
      }
      @media screen and (max-width: 1280px) {
        /*1280*/
        left: 39%;
      }
    }



    z-index: 99;
    width: 1140px;
    /* margin: 0 auto; */
    margin-left: -462px;
    /*margin-left: -590px;*/
    .index_1{
      display: flex;
      justify-content: space-between;
    }
    .checkbox_group{
      display: flex;
      /*justify-content: space-between;*/
      flex-wrap: wrap;
      flex: 1;
      .checkbox {
        width: 125px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        display: flex;
        .checkbox_img {
          width: 30px;
          height: 20px;
          display: block;
        }
        .el-checkbox__label{
          display: flex;
        }
      }
    }

    .query-btns {
      width: 98px;
      height: 36px;
      background: @blue url("@{imgUrl}common/search_icon.png") no-repeat
      center;
      background-size:20px auto;
      margin-right: 10px;
      &:hover {
        background: @blueHover url("@{imgUrl}common/search_icon.png")
        no-repeat center;
        background-size:20px auto;
      }
    }

    &.actives{
      width: 1200px!important;
    }
  }

  .query040 {
    min-height: calc(100vh - 130px);
    position: relative;
    padding-top: 130px;
    /*background: #fff;*/
    .status_wrap {
      max-width: 1200px;
      position: relative;
      margin: 0 auto;
      .status-box {
        width: 110px;
        height: 109px;
        position: absolute;
        right: 0;
        top: -66px;
        span {
          position: absolute;
          bottom: 25px;
          left: 12px;
          font-size: 14px;
          color: #6c94fa;
          &.active {
            cursor: pointer;
          }
        }
      }
    }
    .input_list_wrap {
      .fish_bj {
        width: 131px;
        margin: 150px auto -150px;
        img {
          display: block;
          width: 100%;
        }
      }
    }

    .query-second-conditon{
      overflow: hidden;
      background: #FFFFFF;
      .dib-selecte-country{
        height: auto!important;
        width: 1093px;
        margin-right: 10px;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        overflow: visible;
        .li-selecte-country{
          margin-bottom: 14px!important;
          color: #333333;
          background: #B9DFFF;
          &.active{
            background: @blue;
            color: #FFFFFF;
          }
          span{
            padding: 5px 10px;
          }
          &.actives{
            span{
              padding: 7px 10px;
            }
          }
          font-size: 14px;
          font-weight: 500;
          margin-right: 10px;
          height: 32px;
        }
      }
      .category{
        box-sizing: border-box;
        padding-left: 58px;
        /*width: 1200px;*/
        /*margin: 0 auto;*/
        height: 48px;
        background: rgba(119,148,249,0.06);
        border-radius: 5px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 27px;
        &:before{
          position: absolute;
          content: '';
          width: 4px;
          height: 22px;
          background: #0D419D;
          top: 13px;
          left: 38px;
        }
      }
      /*padding: 0 100px;*/
      .item {
        font-size: 14px;
        letter-spacing: 1px;
        color: #222222;
        min-height: 45px;
        .title,
        ul,
        .more {
          vertical-align: top;
        }
        position: relative;
        .title,
        .more {
          width: 112px;
          position: absolute;
          z-index: 1;
          top: 90px;
        }
        .title {
          left: 0;
        }
        .more {
          right: -12px;
          color: #999999;
          text-align: center;
          cursor: pointer;
          i {
            display: inline-block;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            right: 26px;
            background: url("@{imgUrl}query/down_icon.png") no-repeat;
            background-size: 100%;
            transition: all 0.5s linear;
            &.up {
              transform: rotate(-180deg);
            }
          }
          &:hover {
            color: @blue;
            i {
              background: url("@{imgUrl}query/down_active_icon.png") no-repeat;
              background-size: 100%;
            }
          }
        }
        ul {
          position: relative;
          padding-top: 7px;
          left: 0;
          height: 125px;
          overflow: hidden;
          &.isOpen {
            height: auto;
            overflow: visible;
          }
          li {
            cursor: pointer;
            margin-bottom: 37px;
            position: relative;
            &.isCheck {
              /*background: #5B7DF6;*/
              /*color: #FDFDFD;*/
              /*box-shadow: 0px 4px 8px 0px rgba(26, 61, 191, 0.22), 0px 6px 6px 0px rgba(26, 61, 191, 0.97);*/
            }
            .check-circle {
              position: absolute;
              right: -8px;
              top: -4px;
              background: @blue;
              border-radius: 50%;
              overflow: hidden;
              text-align: center;
              width: 15px;
              height: 15px;
              line-height: 15px;
              color: #ffffff;
              z-index: 1;
              img {
                display: block;
                width: 100%;
              }
            }
            &.li-category {
              width: 62px;
              height: 34px;
              font-size: 14px;
              font-weight: 400;
              color: #555555;
              line-height: 34px;
              background: #FFFFFF;
              box-shadow: 0px 6px 10px 0px rgba(26, 61, 191, 0.18) ;
              border-radius: 8px;
              text-align: center;
              &.isCheck {
                background: #5B7DF6;
                color: #FDFDFD;
                box-shadow: 0px 4px 8px 0px rgba(26, 61, 191, 0.22), 0px 6px 6px 0px rgba(26, 61, 191, 0.97) inset;
              }
            }
            &.li-status {
              width: 90px;
              line-height: 30px;
              border: 1px solid #dadada;
              text-align: center;
              &:hover {
                color: @blue;
                border: 1px solid @blue;
              }
            }
          }
        }
      }
      .selecteBtn{
        margin: 0 auto;
        width: 1021px;
        display: flex;
        justify-content: space-between;
        padding: 0 71px;
        align-items: center;
      }
      .selecteBtns{
        margin-bottom: 43px;
        margin: 0 125px;
        width: 1021px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .btns {
        margin-top: 28px;
        .btn {
          display: inline-block;
          cursor: pointer;
          font-size: 14px;
          letter-spacing: 1px;
          color: #454545;
          width: 90px;
          height: 32px;
          line-height: 32px;
          border-radius: 5px;
          border: solid 1px @blue;
          text-align: center;
          margin-right: 20px;
          background: @blue;
          color: #fff;
          &:nth-child(1) {
            &:hover {
              box-shadow: @boxShadow;
            }
          }
          &.active {
            color: @blue;
            background: #fff;
          }
          &:nth-child(2) {
            &:hover {
              color: #fff;
              background: @blue;
            }
          }
        }
      }
    }

    .dib-vam-category{
      margin-top: 30px!important;
      box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.18);
      border-radius: 8px;
      margin-bottom: 30px!important;
    }

    .dib-vam-fz0 {
      &.dib-vam-fz0-item{
        padding-left: 67px;
        background: #FFFFFF;
      }
      font-size: 0;
      & > * {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
      }
    }
    .vh {
      visibility: hidden;
    }
    &.have-result {
      background: #f3f5fa;
      padding-top: 170px;
      .input_list_wrap {
        background: #fff;
        border: 1px solid #dbdee5;
        top: 0;
        position: fixed;
        width: 100%;
        z-index: 2;
        margin-top: -85px;
        height: 268px;

        .query-box {
          height: 46px;
          margin-bottom: 0;
        }

        .vh {
          visibility: inherit;
        }
      }
    }
    .blank {
      height: 5px;
      background-color: #f3f5fa;
    }

    .sub_disclaimers{
      /*width: 410px;*/
      margin: 100px auto 0;
      padding-bottom: 50px;
      font-size: 12px;
      line-height: 22px;
    }

    .disclaimers{
      width: 410px;
      position: absolute;
      left: 50%;
      margin-left: -205px;
      bottom: 20px;
      font-size: 12px;
      line-height: 22px;
    }

    .query-box {
      .radio {
        position: fixed;
        top: 94px;
        left: 50%;
        margin-top: 8px !important;
        margin-left: -600px !important;
        margin-bottom: 0px !important;
      }
      height: 100px;
      /*background: #ffffff;*/
      margin-bottom: 12px;
      &.vertical-horizon-center {
        height: 85px;
        margin-bottom: auto;
        .top {
          height: 61px;
        }
      }
      .query-inner {
        width: 832px;
        margin: 0 auto;
      }
      .top {
        height: 44px;
        text-align: center;
        position: relative;
        .announcement {
          position: absolute;
          bottom: 0;
          left: 0;
          .icon-warning-circle {
            color: @blue;
            font-size: 16px;
            margin-right: 7px;
          }
          span {
            font-size: 12px;
            letter-spacing: 2px;
            color: #b7b6b6;
          }
        }
        .river-horse-courtyard {
          width: 93px;
          height: 86px;
          margin: 0 auto;
          position: relative;
          padding-bottom: 20px;
          .river-horse-home {
            width: 100%;
            height: 100%;
            opacity: 0;
            img {
              width: 100%;
              height: 100%;
            }
            animation: riverHorse_query 1s 0.7s linear forwards;
          }
        }
      }
      .input_search {
        height: 46px;
        margin: 0px auto 0;
        width: 1200px;
        .input-single {
          height: 46px;
          border-radius: 5px;
          border: solid 1px #c2c6d0;
          display: flex;

          position: relative;
          .category {
            width: 101px;
            text-align: right;
            font-size: 14px;
            letter-spacing: 1px;
            color: #333333;
            cursor: pointer;
          }
          .input_box {
            flex: 1;
            position: relative;
            border-left: 1px solid #c2c6d0;
            .input-con {
              width: 100%;
              height: 100%;
              border: 0; // 去除未选中状态边框
              outline: none; // 去除选中状态边框
              background-color: rgba(0, 0, 0, 0); // 透明背景
              .el-input-group__prepend{
                /*display: flex;*/
                /*align-items: center;*/
                width: 100px;
                position: relative;
                background: #193dbe;
                box-sizing: border-box;

              }
              .el-icon-caret-bottom{
                -webkit-transition: -webkit-transform 0.3s ease;
                -moz-transition: -moz-transform 0.3s ease;
                transition: transform 0.3s ease;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);
                &.rotate{
                  -webkit-transform: rotate(0deg);
                  -moz-transform: rotate(0deg);
                  transform: rotate(0deg);
                }
              }
            }
          }
          .camera2 {
            padding: 0 26px 0 11px;
            font-size: 0;
            background: #fff;
            .el-upload {
              width: 38px;
              .camera-box {
                width: 38px;
                line-height: 46px;
                overflow: hidden;
                &:hover {
                  .camera-btn1 {
                    display: none;
                  }
                  .camera-btn2 {
                    display: inline;
                  }
                }
                .camera-btn {
                  width: 26px;
                  vertical-align: middle;
                }
                .camera-btn1 {
                  display: inline;
                }
                .camera-btn2 {
                  display: none;
                }
              }
            }
          }
          .query-btn {
            cursor: pointer;
            width: 78px;
            height: 48px;
            border-radius: 0 5px 5px 0;
            background: @blue url("@{imgUrl}common/search_icon.png") no-repeat
            center;
            margin-top: -1px;
            margin-left: -2px;
            margin-right: -1px;
            &:hover {
              background: #1A3DBF url("@{imgUrl}common/search_icon.png")
              no-repeat center;
            }
          }
          &.borderBlue {
            border-color: @blue;
            .input_box {
              border-color: @blue;
            }
          }
        }
        .search_btn{
          height: 48px;
          border: 1px solid #1A3DBF;
          border: 1px solid #1A3DBF;
          color: #1A3DBF;
          font-size: 18px;
          &:hover{
            background: #1A3DBF;
            color: #FFFFFF;
          }
        }
      }
    }
    .vertical-horizon-center {
      margin-top: 200px;
    }
    .condtion {
      /*min-height: 243px;*/
      background: #ffffff;
      /*padding: 38px 100px 35px;*/
      padding: 32px 100px;
      .first-condtion {
        border-bottom: 1px solid #d5d4d4;
        margin-bottom: 25px;
        li {
          height: 32px;
          font-size: 16px;
          letter-spacing: 2px;
          color: #666666;
          margin-right: 70px;
          line-height: 29px;
          /*border-bottom: 3px solid transparent;*/
          cursor: pointer;
          &:hover {
            color: @blue;
          }
          &:nth-of-type(2) {
            position: relative;
            &:after {
              position: absolute;
              width: 1px;
              height: 33px;
              background-color: @blue;
              content: "";
              top: -5px;
              left: 123px;
            }
          }
          position: relative;
          &:before {
            position: absolute;
            width: 0;
            height: 3px;
            background: @blue;
            content: "";
            display: inline-block;
            bottom: 0;
            transition: all 0.2s linear;
          }
          &.active {
            color: @blue;
            &:before {
              position: absolute;
              width: 100%;
              height: 3px;
              background: @blue;
              content: "";
              display: inline-block;
              bottom: 0;
            }
            /*border-bottom: 3px solid @blue;*/
          }
        }
      }
      .second-conditon {
        .item {
          font-size: 14px;
          letter-spacing: 1px;
          color: #222222;
          min-height: 45px;
          .title,
          ul,
          .more {
            vertical-align: top;
          }
          position: relative;
          .title,
          .more {
            width: 112px;
            position: absolute;
            z-index: 1;
            top: 7px;
          }
          .title {
            left: 0;
          }
          .more {
            right: 0;
            color: #999999;
            text-align: center;
            cursor: pointer;
            i {
              display: inline-block;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 50%;
              margin-top: -6px;
              right: 26px;
              background: url("@{imgUrl}query/down_icon.png") no-repeat;
              background-size: 100%;
              transition: all 0.5s linear;
              &.up {
                transform: rotate(-180deg);
              }
            }
            &:hover {
              color: @blue;
              i {
                background: url("@{imgUrl}query/down_active_icon.png") no-repeat;
                background-size: 100%;
              }
            }
          }
          ul {
            position: relative;
            /*padding-left: 112px;*/
            /*padding-right: 112px;*/
            padding-top: 7px;
            left: 0;
            height: 40px;
            overflow: hidden;
            &.isOpen {
              height: auto;
              overflow: visible;
            }
            li {
              cursor: pointer;
              margin-bottom: 12px;
              position: relative;
              &.isCheck {
                /*color: red;*/
              }
              .check-circle {
                position: absolute;
                right: -8px;
                top: -4px;
                background: @blue;
                border-radius: 50%;
                overflow: hidden;
                text-align: center;
                width: 15px;
                height: 15px;
                line-height: 15px;
                color: #ffffff;
                z-index: 1;
                img {
                  display: block;
                  width: 100%;
                }
              }
              &.li-category {
                width: 57px;
                line-height: 30px;
                border: 1px solid #dadada;
                text-align: center;
                &:hover {
                  color: @blue;
                  border: 1px solid @blue;
                }
              }
              &.li-status {
                width: 90px;
                line-height: 30px;
                border: 1px solid #dadada;
                text-align: center;
                &:hover {
                  color: @blue;
                  border: 1px solid @blue;
                }
              }
            }
          }
        }
        .btns {
          margin-top: 28px;
          .btn {
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
            letter-spacing: 1px;
            color: #454545;
            width: 90px;
            height: 32px;
            line-height: 32px;
            border-radius: 5px;
            border: solid 1px @blue;
            text-align: center;
            margin-right: 20px;
            background: @blue;
            color: #fff;
            &:nth-child(1) {
              &:hover {
                box-shadow: @boxShadow;
              }
            }
            &.active {
              color: @blue;
              background: #fff;
            }
            &:nth-child(2) {
              &:hover {
                color: #fff;
                background: @blue;
              }
            }
          }
        }
      }
    }
    .result {
      height: 100%;
      display: flex;
      .screen{
        width: 293px;
        /*padding-left: 20px;*/
        /*height: calc(100% - 380px);*/
        overflow-y: auto;
        background: #E7F3FC;
        .screen_form{
          overflow-y: auto;
          height: calc(100vh - 300px);
        }

        & .header-icon{
          white-space: nowrap;
        }
      }
      .screen_content {
        background: #FFFFFF;
        /*width: calc(100vw - 310px);*/
        width: 100%;
      }
      min-height: 200px;
      /*background: #ffffff;*/
      padding-bottom: 90px;
      .table-icon {
        height: 70px;
        background: white;
        display: flex;
        align-items: center;
        padding-left: 50px;
        margin-bottom: 10px;
        /*padding-left: 20px;*/
        /*padding-right: 100px;*/
        .report{
          background-image: url("@{imgUrl}query/report.png");
        }
        ul.switch-style {
          font-size: 0;
          margin-right: 35px;
          background-image: url("@{imgUrl}query/switch1.png");
          &.switch-bg {
            background-image: url("@{imgUrl}query/switch2.png");
          }
          background-size: 100% 100%;
          width: 220px;
          li {
            display: inline-block;
            font-size: 13px;
            width: 110px;
            height: 46px;
            color: #353535;
            /*border: solid 1px #637a91;*/
            line-height: 43px;
            text-align: center;
            cursor: pointer;
            &.style1 {
              border-radius: 3px 0 0 3px;
            }
            &.style2 {
              border-radius: 0px 3px 3px 0px;
              /*border-left: 0px;*/
            }
            & > * {
              vertical-align: middle;
            }
            span {
            }
            &.active {
              color: #4f9bfa;
            }
          }
        }
        .switch-style-container {
          .left,
          .right {
            width: 18px;
            height: 18px;
            background-image: url("@{imgUrl}query/switchbtn.png");
            cursor: pointer;
          }
          .left {
            margin-right: 44px;
            background-position: -36px 0;
            position: relative;
            &:after {
              position: absolute;
              content: "";
              width: 1px;
              height: 20px;
              background: #cecece;
              right: -25px;
              top: -1px;
            }
            &.active,
            &:hover {
              background-position: -57px 0;
            }
          }
          .right {
            background-position: 0 0;
            &.active,
            &:hover {
              background-position: -18px 0;
            }
          }
          .download_icon {
            display: inline-block;
            width: 16px;
            height: 20px;
            background: url("@{imgUrl}common/download.png") center no-repeat;
            cursor: pointer;
            background-size: 90%;
            margin-left: 30px;
            &:hover {
              background: url("@{imgUrl}common/download_hover.png") center
              no-repeat;
              background-size: 90%;
            }
          }
        }
      }
      .table {
        li {
          display: flex;
          box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.05);
          /*border-radius: 8px;*/
          margin-bottom: 10px;
          border-radius: 10px;
          /*padding: 0 100px;*/
          height: 268px!important;
          background: #ffffff;
          /*&:nth-of-type(2n) {*/
          /*  background: #f2f4fa;*/
          /*}*/
          & > * {
            vertical-align: top;
          }
          .check_ele {
            margin-top: 104px;
            margin-right: 30px;
          }
          .left {
            width: 234px;
            height: 196px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            border: 1px solid #B1DBFD;
            box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.08);
            border-radius: 5px;
            line-height: 138px;
            text-align: center;
            margin-top: 33px;
            margin-right: 45px;
            cursor: pointer;
            img {
              /* max-width: 73%; */

            }
          }
          .right {
            .height_level{
              padding-right: 10%;
              @media screen and (max-width: 1280px) {
                /*1280*/
                padding-right: 2%!important;
              }

            }
            width: calc(100% - 283px); //138+45
            padding-top: 44px;
            line-height: 1;
            .text {
              /*width: calc(100% - 50px);*/
              font-size: 0;
              .text-level,
              .level-graphical {
                display: inline-block;
                vertical-align: top;
              }
              &.level-high {
                .text-level {
                  color: #ff5a5a;
                }
                .level-graphical {
                  /*background: #eb5657;*/
                  background-image: url("@{imgUrl}query/levels.png");
                  background-repeat: no-repeat;
                  background-position: -120px 0;
                }
              }
              &.level-middle {
                .text-level {
                  color: #fcbc41;
                }
                .level-graphical {
                  /*background: #fcbc41;*/
                  background-image: url("@{imgUrl}query/levels.png");
                  background-repeat: no-repeat;
                  background-position: -60px 0;
                }
              }
              &.level-low {
                .text-level {
                  color: #23c994;
                }
                .level-graphical {
                  /*background: #23c994;*/
                  background-image: url("@{imgUrl}query/levels.png");
                  background-repeat: no-repeat;
                  background-position: 0 0;
                }
              }
              .text-level {
                font-style: normal;
                font-size: 14px;
                letter-spacing: 1px;
                margin-left: 10px;
              }
              .level-graphical {
                width: 60px;
                height: 8px;
                display: inline-block;
                margin-top: 4px;
              }
            }
            .trademark-name {
              display: flex;
              align-items: center;
              letter-spacing: 2px;
              color: #222222;
              margin-bottom: 23px;
              cursor: pointer;
              span:nth-of-type(1){
                font-size: 18px;
                font-weight: 500;
                color: #1A3DBF;
              }
              span:nth-of-type(2){
                margin-left: 37px;
                margin-right: 25px;
                display: flex;
                padding: 8px 10px;
                background: #E7F3FC;
                border-radius: 5px!important;
                font-size: 14px;
                font-weight: 400;
                color: #0D419D;
              }
              span:nth-of-type(3){
                font-size: 14px;
                font-weight: 400;
                display: inline-block;
                padding: 8px 10px;
                border-radius: 5px!important;
              }
            }
            .details-container {
              .details-item {
                vertical-align: top;
                .small-item {
                  font-size: 0;
                  .title,
                  .text {
                    display: inline-block;
                  }
                  .title {
                    color: #999999;
                  }
                  .text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
                &.a {
                  width: 30%;
                  .title {
                    width: 85px;
                  }
                  .text {
                    width: calc(100% - 100px);
                  }
                }
                &.b {
                  width: 30%;
                  .title {
                    width: 85px;
                  }
                  .text {
                    width: calc(100% - 85px);
                  }
                }

                @media screen and (max-width: 1280px) {
                  /*1280*/
                  &.b {
                    width: 40%;
                    .title {
                      width: 85px;
                    }
                    .text {
                      width: calc(100% - 85px);
                    }
                  }
                }


                &.c {
                  width: 30%;
                  .title {
                    width: 74px;
                  }
                  .text {
                    width: calc(100% - 74px);
                  }
                }
                p {
                  font-size: 0;
                  line-height: 18px;
                  .title,
                  .text {
                    vertical-align: top;
                    font-size: 14px;
                    letter-spacing: 1px;
                    color: #454545;
                  }
                  .title {
                    margin-bottom: 17px;
                  }
                  .text {
                  }
                }
              }
            }
          }
          &:hover{
            box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.22);
          }
        }

        &.pq_active{
          li {
            display: flex;
            box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.05);
            /*border-radius: 8px;*/
            margin-bottom: 10px;
            border-radius: 10px;
            /*padding: 0 100px;*/
            height: 268px!important;
            background: #ffffff;
            /*&:nth-of-type(2n) {*/
            /*  background: #f2f4fa;*/
            /*}*/
            & > * {
              vertical-align: top;
            }
            .check_ele {
              margin-top: 104px;
              margin-right: 30px;
            }
            .left {
              width: 234px;
              height: 196px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #FFFFFF;
              border: 1px solid #B1DBFD;
              box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.08);
              border-radius: 5px;
              line-height: 138px;
              text-align: center;
              margin-top: 33px;
              margin-right: 45px;
              cursor: pointer;
              img {
                /* max-width: 73%; */

              }
            }
            .right {
              .height_level{
                padding-right: 10%;
                @media screen and (max-width: 1280px) {
                  /*1280*/
                  padding-right: 2%!important;
                }

              }
              width: calc(100% - 283px); //138+45
              padding-top: 44px;
              line-height: 1;
              .text {
                /*width: calc(100% - 50px);*/
                font-size: 0;
                .text-level,
                .level-graphical {
                  display: inline-block;
                  vertical-align: top;
                }
                &.level-high {
                  .text-level {
                    color: #ff5a5a;
                  }
                  .level-graphical {
                    /*background: #eb5657;*/
                    background-image: url("@{imgUrl}query/levels.png");
                    background-repeat: no-repeat;
                    background-position: -120px 0;
                  }
                }
                &.level-middle {
                  .text-level {
                    color: #fcbc41;
                  }
                  .level-graphical {
                    /*background: #fcbc41;*/
                    background-image: url("@{imgUrl}query/levels.png");
                    background-repeat: no-repeat;
                    background-position: -60px 0;
                  }
                }
                &.level-low {
                  .text-level {
                    color: #23c994;
                  }
                  .level-graphical {
                    /*background: #23c994;*/
                    background-image: url("@{imgUrl}query/levels.png");
                    background-repeat: no-repeat;
                    background-position: 0 0;
                  }
                }
                .text-level {
                  font-style: normal;
                  font-size: 14px;
                  letter-spacing: 1px;
                  margin-left: 10px;
                }
                .level-graphical {
                  width: 60px;
                  height: 8px;
                  display: inline-block;
                  margin-top: 4px;
                }
              }
              .trademark-name {
                display: flex;
                align-items: center;
                letter-spacing: 2px;
                color: #222222;
                margin-bottom: 23px;
                cursor: pointer;
                span:nth-of-type(1){
                  font-size: 18px;
                  font-weight: 500;
                  color: #1A3DBF;
                }
                span:nth-of-type(2){
                  margin-left: 37px;
                  margin-right: 25px;
                  display: flex;
                  padding: 8px 10px;
                  background: #E7F3FC;
                  border-radius: 5px!important;
                  font-size: 14px;
                  font-weight: 400;
                  color: #0D419D;
                }
                span:nth-of-type(3){
                  font-size: 14px;
                  font-weight: 400;
                  display: inline-block;
                  padding: 8px 10px;
                  border-radius: 5px!important;
                }
              }
              .details-container {
                .details-item {
                  vertical-align: top;
                  .small-item {
                    font-size: 0;
                    .title,
                    .text {
                      display: inline-block;
                    }
                    .title {
                      color: #999999;
                    }
                    .text {
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  &.a {
                    width: 30%;
                    .title {
                      width: 135px;
                    }
                    .text {
                      width: calc(100% - 135px);
                    }
                  }
                  &.b {
                    width: 30%;
                    .title {
                      width: 140px;
                    }
                    .text {
                      width: calc(100% - 140px);
                    }
                  }

                  @media screen and (max-width: 1280px) {
                    /*1280*/
                    &.b {
                      width: 40%;
                      .title {
                        width: 135px;
                      }
                      .text {
                        width: calc(100% - 135px);
                      }
                    }
                  }


                  &.c {
                    width: 30%;
                    .title {
                      width: 135px;
                    }
                    .text {
                      width: calc(100% - 135px);
                    }
                  }
                  p {
                    font-size: 0;
                    line-height: 18px;
                    .title,
                    .text {
                      vertical-align: top;
                      font-size: 14px;
                      letter-spacing: 1px;
                      color: #454545;
                    }
                    .title {
                      margin-bottom: 17px;
                    }
                    .text {
                    }
                  }
                }
              }
            }
            &:hover{
              box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.22);
            }
          }
        }
      }
      .result-ico {
        font-size: 0;
        /*width: calc(100vw - 313px);*/
        width: 100%;
        background: #FFFFFF;
        /*margin: 0 auto;*/
        padding: 10px 67px;
        box-sizing: border-box;

        li {
          border-radius: 8px;
          transition: all 1s ease-out;
          box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.05);
          width: 300px;
          height: 375px;
          /*border: solid 1px #c7c7c7;*/
          display: inline-block;
          /*margin-left: 15px;*/
          margin-right: 37px;
          margin-bottom: 40px;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          box-sizing: border-box;
          dl{
            padding: 0 34px;
            dd:nth-of-type(1){
              display: inline-block;
              padding: 7px 11px;
              color: #0D419D;
              font-size: 14px;
              background: #E7F3FC;
              border-radius: 3px;
              box-sizing: border-box;
            }
            dd:nth-of-type(2){
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              margin-top: 12px;
            }
            dd:nth-of-type(3){
              width: 214px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              margin-top: 11px;
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap
            }
          }
          &:hover {
            .see-big-img {
              display: block;
            }
          }
          .trademark-pattern {
            padding: 27px 33px 16px 33px;
            .trademark-pattern-img{
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 234px;
              height: 220px;
              background: #FFFFFF;
              border: 1px solid #92A5EE;
              box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.08);
              border-radius: 5px;
              .state{
                position: absolute;
                bottom: -1px;
                left: -1px;
                font-size: 14px;
                font-weight: 400;
                display: inline-block;
                padding: 8px 10px;
                border-radius: 5px!important;
              }
              .small-item{
                position: absolute;
                top: 10px;
                right: 15px;
                .text {
                  width: calc(100% - 50px);
                  font-size: 0;
                  .text-level,
                  .level-graphical {
                    display: inline-block;
                    vertical-align: top;
                  }
                  &.level-high {
                    .text-level {
                      color: #ff5a5a;
                    }
                    .level-graphical {
                      /*background: #eb5657;*/
                      background-image: url("@{imgUrl}query/levels.png");
                      background-repeat: no-repeat;
                      background-position: -120px 0;
                    }
                  }
                  &.level-middle {
                    .text-level {
                      color: #fcbc41;
                    }
                    .level-graphical {
                      /*background: #fcbc41;*/
                      background-image: url("@{imgUrl}query/levels.png");
                      background-repeat: no-repeat;
                      background-position: -60px 0;
                    }
                  }
                  &.level-low {
                    .text-level {
                      color: #23c994;
                    }
                    .level-graphical {
                      /*background: #23c994;*/
                      background-image: url("@{imgUrl}query/levels.png");
                      background-repeat: no-repeat;
                      background-position: 0 0;
                    }
                  }
                  .text-level {
                    font-style: normal;
                    font-size: 14px;
                    letter-spacing: 1px;
                    margin-left: 10px;
                  }
                  .level-graphical {
                    width: 60px;
                    height: 8px;
                    display: inline-block;
                    margin-top: 4px;
                  }
                }
              }
            }
            /*padding: 15px;*/
            height: 220px;
            text-align: center;
            position: relative;
            img {
              height: 50%;
              display: block;
            }
          }
          .trademark-reg,
          .see-big-img {
            height: 30px;
            background-color: #616060;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
            color: #ffffff;
            text-align: center;
          }
          .trademark-reg {
            cursor: default;
          }
          .see-big-img {
            position: absolute;
            width: 100%;
            top: 0;
            display: none;
            z-index: 2;
            cursor: pointer;
          }
          .showbig {
            position: fixed;
            width: 800px;
            background: red;
            z-index: 999;
          }
          &:hover{
            box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.22);
            transform:scale(1.01);
          }
        }

        &.pq_active{
          li {
            .trademark-pattern {
              padding: 27px 33px 16px 33px;
              .trademark-pattern-img{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 234px;
                height: 220px;
                background: #FFFFFF;
                border: 1px solid #92A5EE;
                box-shadow: 0px 0px 8px 0px rgba(18, 17, 28, 0.08);
                border-radius: 5px;
                .state{
                  position: absolute;
                  bottom: -1px;
                  left: -1px;
                  font-size: 14px;
                  font-weight: 400;
                  display: inline-block;
                  padding: 8px 10px;
                  border-radius: 5px!important;
                }
                .small-item{
                  position: absolute;
                  top: 10px;
                  right: 15px;
                  .text {
                    width: calc(100% - 50px);
                    font-size: 0;
                    .text-level,
                    .level-graphical {
                      display: inline-block;
                      vertical-align: top;
                    }
                    &.level-high {
                      .text-level {
                        color: #ff5a5a;
                      }
                      .level-graphical {
                        /*background: #eb5657;*/
                        background-image: url("@{imgUrl}query/levels.png");
                        background-repeat: no-repeat;
                        background-position: -120px 0;
                      }
                    }
                    &.level-middle {
                      .text-level {
                        color: #fcbc41;
                      }
                      .level-graphical {
                        /*background: #fcbc41;*/
                        background-image: url("@{imgUrl}query/levels.png");
                        background-repeat: no-repeat;
                        background-position: -60px 0;
                      }
                    }
                    &.level-low {
                      .text-level {
                        color: #23c994;
                      }
                      .level-graphical {
                        /*background: #23c994;*/
                        background-image: url("@{imgUrl}query/levels.png");
                        background-repeat: no-repeat;
                        background-position: 0 0;
                      }
                    }
                    .text-level {
                      font-style: normal;
                      font-size: 14px;
                      letter-spacing: 1px;
                      margin-left: 10px;
                    }
                    .level-graphical {
                      width: 60px;
                      height: 8px;
                      display: inline-block;
                      margin-top: 4px;
                    }
                  }
                }
              }
              /*padding: 15px;*/
              height: 220px;
              text-align: center;
              position: relative;
              img {
                height: 50%;
                display: block;
              }
            }
            .trademark-reg,
            .see-big-img {
              height: 30px;
              background-color: #616060;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ffffff;
              text-align: center;
            }
            .trademark-reg {
              cursor: default;
            }
            .see-big-img {
              position: absolute;
              width: 100%;
              top: 0;
              display: none;
              z-index: 2;
              cursor: pointer;
            }
            .showbig {
              position: fixed;
              width: 800px;
              background: red;
              z-index: 999;
            }
            &:hover{
              box-shadow: 0px 0px 10px 0px rgba(30, 65, 194, 0.22);
              transform:scale(1.01);
            }
          }
        }
      }
    }
    .no-result {
      display: block;
      height: 200px;
      font-size: 0px;
      border-bottom: 3px solid transparent;
      text-align: center;
      background: #fff;
      font-size: 15px;
      color: #949aa9;
      padding: 20px 0 36px;
      i {
        display: block;
        width: 80px;
        height: 80px;
        margin: 30px auto 40px;
        img {
          width: 100%;
        }
      }
      /*margin: 0px 100px 35px;*/
      /*box-shadow: 0px 1px 12px 0px rgba(47, 47, 47, 0.12);*/
    }
  }

  .query-result-ico-popover {
    width: 650px !important;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(63, 63, 63, 0.32);
    border-radius: 3px;
    border: solid 1px #d7d7d7;
    .container {
      padding: 12px 20px;
      font-size: 0;
      .top {
        & > * {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
        }
        .left {
          .tuyang {
            width: 112px;
            height: 117px;
            border: solid 1px #d0d0d0;
            position: relative;
            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              max-width: 95%;
              max-height: 95%;
            }
          }
          .degree,
          .zan {
            margin-top: 6px;
            font-size: 0;
            & > * {
              vertical-align: middle;
              font-size: 13px;
              display: inline-block;
            }
            .title {
              width: 71px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #454545;
              display: inline-block;
              word-break: normal;
              margin-right: 15px;
            }
            .text {
              font-size: 14px;
              letter-spacing: 1px;
              color: #ef6c6d;
            }
            .feedbackImgLaughone {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -50px 0;
            }
            .feedbackImgCryone {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: 0 0;
            }
            .feedbackImgLaughtwo {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -75px 0;
            }
            .feedbackImgCrytwo {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -25px 0;
            }
          }
        }
        .right {
          margin-left: 10px;
          li {
            margin-bottom: 10px;
            & > * {
              display: inline-block;
              vertical-align: top;
              font-size: 12px;
            }
            .title {
              width: 94px;
              font-size: 14px;
              letter-spacing: 1px;
              color: #666666;
              word-break: normal;
              margin-right: 15px;
              /*background: red;*/
            }
            .text {
              font-size: 13px;
              letter-spacing: 1px;
              color: #333333;
              /*background: green;*/
              width: 310px;
              overflow: hidden;
              text-overflow: ellipsis;
              /*word-break:normal;*/
            }
          }
        }
      }
      .line {
        height: 1px;
        border: solid 1px #d3d3d3;
      }
      .bottom {
        text-align: center;
        .btn {
          display: inline-block;
          width: 155px;
          height: 30px;
          line-height: 30px;
          border-radius: 3px;
          border: solid 1px #eb5657;
          margin-top: 15px;
          font-size: 13px;
          letter-spacing: 1px;
          color: #eb5657;
          cursor: pointer;
          &:hover {
            background: #eb5657;
            color: #ffffff;
          }
        }
      }
      &.pq_active{
        .top {
          & > * {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
          }
          .left {
            .tuyang {
              width: 112px;
              height: 117px;
              border: solid 1px #d0d0d0;
              position: relative;
              img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 95%;
                max-height: 95%;
              }
            }
            .degree,
            .zan {
              margin-top: 6px;
              font-size: 0;
              & > * {
                vertical-align: middle;
                font-size: 13px;
                display: inline-block;
              }
              .title {
                width: 71px;
                font-size: 12px;
                letter-spacing: 1px;
                color: #454545;
                display: inline-block;
                word-break: normal;
                margin-right: 15px;
              }
              .text {
                font-size: 14px;
                letter-spacing: 1px;
                color: #ef6c6d;
              }
              .feedbackImgLaughone {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: -50px 0;
              }
              .feedbackImgCryone {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: 0 0;
              }
              .feedbackImgLaughtwo {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: -75px 0;
              }
              .feedbackImgCrytwo {
                height: 26px;
                width: 25px;
                cursor: pointer;
                border: 0;
                background-color: #fff;
                margin-right: 5px;
                background-size: auto;
                background-image: url("@{imgUrl}query/updown.png");
                background-position: -25px 0;
              }
            }
          }
          .right {
            margin-left: 10px;
            li {
              margin-bottom: 10px;
              & > * {
                display: inline-block;
                vertical-align: top;
                font-size: 12px;
              }
              .title {
                width: 150px!important;
                font-size: 14px;
                letter-spacing: 1px;
                color: #666666;
                word-break: normal;
                margin-right: 15px;
                /*background: red;*/
              }
              .text {
                font-size: 13px;
                letter-spacing: 1px;
                color: #333333;
                /*background: green;*/
                width: 310px;
                overflow: hidden;
                text-overflow: ellipsis;
                /*word-break:normal;*/
              }
            }
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  @media screen and (max-width: 1680px) {
    /*至1680*/
    .query040 {
      .result {
        .result-ico {
          width: 1210px;
          /*background: green;*/
          ul {
            li {
              width: 180px;
              height: 190px;
              margin-left: 10px;
              margin-right: 10px;
              .trademark-pattern {
                height: 190-30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    /*至1680*/
    /*.query040 {*/
    /*  min-height: calc(100vh - -50px);*/
    /*}*/
  }

  @media screen and (max-width: 1500px) {
    /*至1366*/
    .query040 {
      .result {
        /*表格版样式*/
        ul.table {
          li {
            .right {
              .details-container {
                .d {
                  /*background: orange;*/
                  .small-item {
                    /*background: yellow;*/
                    .title {
                      /*background: greenyellow;*/
                      width: 50px;
                    }
                    .text {
                      /*background: blue;*/
                    }
                  }
                }
              }
            }
          }
        }
        /*图标版样式*/
        ul.result-ico {
          width: 1210px;
          /*background: red;*/
          ul {
            li {
              width: 180px;
              height: 190px;
              margin-left: 10px;
              margin-right: 10px;
              .trademark-pattern {
                height: 190-30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    /*至1280*/
    .query040 {
      .result {
        ul.result-ico {
          width: 968px;
          /*background: yellow;*/
          ul {
            li {
              width: 180px;
              height: 190px;
              margin-left: 10px;
              margin-right: 10px;
              .trademark-pattern {
                height: 190-30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .condtion {
      .first-condtion {
        li {
          margin-right: 50px;
        }
      }
    }
  }
</style>
<style lang="less">
  .query040 {
    .prepend_input{
      position: relative;
      width: 102px;
      height: 50px;
      top: -2px;
      left: -2px;
    }
    .left-secondQueryCondition {
      margin-top: -1px;
      overflow-y: auto;
      overflow-x: hidden;
      width: 200px;
      left: 0;
      .el-form-item {
        margin-bottom: 0;
      }
      .checkBoxDiv {
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
      }
      .imgBoxDiv {
        width: 170px;
        height: 150px;
        border: 1px solid #ccc;
        .upload-drag {
          width: 100%;
          height: 100%;
          .el-upload {
            width: 100%;
            height: 100%;
            .el-upload-dragger {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 85%;
              }
            }
          }
        }
      }
      .textBoxDiv {
        .el-input__inner {
          border: 0 none;
          border-bottom: 2px solid #d8dce5;
          text-align: center;
        }
      }
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
    }

    .input-con input.el-input__inner {
      background: #fff;
      border-radius: inherit;
      border: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      font-size: inherit;
      line-height: 1;
      outline: 0;
      padding: 0 15px;
      width: 100%;
      height: 46px;
      color: inherit;
    }
    .category .el-input {
      height: 46px;
    }
    .category input.el-input__inner {
      width: 100%;
      height: 100%;
      background: #e1e7fb;
      border: none;
      border-radius: 4px 0 0 4px;
    }
    .el-upload-list {
      position: absolute;
      left: 110px;
      top: 0;
      background: #fff;
      &:focus {
        outline: none;
        border: none;
      }
    }
    .el-upload-list--picture .el-upload-list__item {
      border: none;
      border-radius: 0;
      padding: 0 0 0 90px;
      height: 34px;
      line-height: 34px;
      margin-top: 7px;
      transition: none !important;
      &:focus {
        outline: none;
        border: none;
      }
    }
    .el-upload-list--picture .el-upload-list__item-thumbnail {
      width: auto;
      height: 34px;
    }
    .el-upload-list--picture .el-upload-list__item-name {
      margin-top: 0;
      margin-left: -15px;
    }
    .el-upload-list--picture .el-upload-list__item-name i {
      font-size: 0;
    }
    .el-upload-list__item .el-icon-close {
      top: 10px;
    }
  }
  .time_confirm {
    .el-message-box__title {
      font-size: 16px;
      color: #777;
      letter-spacing: 2px;
    }
    .el-message-box__message p {
      font-size: 18px;
      color: #373a42;
      margin: 10px auto 45px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .el-message-box__btns button {
      width: 68px;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
    .el-message-box__btns button:nth-child(2) {
      margin-left: 50px;
      box-shadow: none;
      &:hover {
        box-shadow: @boxShadow;
      }
    }
  }
</style>
